import React from "react";
import "./howItWorks.scss";

const HowItWorks = () => {
  return (
    <div className="work-main">
      <div className="work-wrapper py-5">
        <h4>
          {/* How It Works */}
          Så här funkar det
          </h4>
        <div className="work-container">
          <div className="work-card">
            <div className="heading-container">
              <h1 className="font-bold text-[#23503A]">1</h1>
              {/* <i class="fa fa-book" aria-hidden="true"></i> */}
            </div>
            <p>Välj din bil</p>
            <span>
            Börja med att välja önskade bil och utrustning till den
            </span>
          </div>
          <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          <div className="work-card">
            <div className="heading-container">
              <h1 className="font-bold text-[#23503A]">2</h1>
              {/* <i class="fa fa-book" aria-hidden="true"></i> */}
            </div>
            <p>Samla offerter </p>
            <span>
            Bilförsäljare kommer att skicka ut offerter på din önskade bil
            </span>
          </div>
          <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          <div className="work-card">
            <div className="heading-container">
              <h1 className="font-bold text-[#23503A]">3</h1>
              {/* <i class="fa fa-book" aria-hidden="true"></i> */}
            </div>
            <p>Jämför </p>
            <span>
            Få det LÄGSTA priset och jämför hemifrån
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
