import React, { useState } from "react";
import "./addNewCar.scss";
import carImage from "../../assets/images/getImage.webp";

const ChooseModel = ({ carComponents, setCarComponents }) => {
  const modelsWithPrices = [
    { name: "Camry", savingPrice: 5000 },
    { name: "Accord", savingPrice: 6000 },
    { name: "F-150", savingPrice: 7000 },
    { name: "Cruze", savingPrice: 4500 },
    { name: "X5", savingPrice: 8000 },
    { name: "C-Class", savingPrice: 7500 },
    { name: "A4", savingPrice: 5500 },
  ];

  const [selectedModel, setSelectedModel] = useState(null);

  return (
    <div className="addNewCar-main">
      <div className="addNewCar-wrapper space-y-6">
        <div className="addNewCar-heading-container">
          <h4>Välj Modell</h4>
          <div className="btn-container">
            <button
              className="addNewCar-build-new-car-btn"
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  chooseMake: true,
                  chooseModel: false,
                })
              }
            >
              Tillbaka
            </button>
            <button
              className={`addNewCar-build-new-car-btn ${
                selectedModel ? "" : "disabled"
              }`}
              disabled={!selectedModel}
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  chooseType: true,
                  chooseModel: false,
                })
              }
            >
              Nästa
            </button>
          </div>
        </div>

        <div className="model-options-container">
          {modelsWithPrices.map(({ name, savingPrice }, index) => (
            <div
              key={index}
              className={`model-option ${
                selectedModel === name ? "selected" : ""
              }`}
              onClick={() => setSelectedModel(name)}
            >
              <div className="name-container">
                <p>{name}</p>
                <p>Average Saving Price: ${savingPrice}</p>
              </div>

              <img src={carImage} alt="carImage" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseModel;
