import React, { useState, useEffect } from "react";
import MyCars from "../components/dashboard/myCars/myCars";
import DelaerDashboard from "../components/dashboard/myCars/DelaerDashboard";
import useAuth from "../hooks/useAuth";

const Dashboard = () => {
  const {getLoggedObject, setLoggedObject, isLoggedIn, checkLogin} = useAuth();
  const [type, setType] = useState(0);
  useEffect(()=>{
      setType(getLoggedObject()?.user_type);
  },[])

  return (
    <>
      {
        type == 2 ?
          <DelaerDashboard />
          :
          <MyCars />
      }
    </>
  );
};

export default Dashboard;
