import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "./Layout";
import Home from "../pages/Home";
import TermsConditions from "../pages/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import FraudDisclaimer from "../pages/FraudDisclaimer";
import FAQ from "../pages/FAQ";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import CustomerLogin from "../pages/CustomerLogin";
import DealerLogin from "../pages/DealerLogin";
import BuyerSignup from "../pages/BuyerSignup";
import ForgotPassword from "../pages/ForgotPassword";
import VerificationCode from "../pages/VerificationCode";
import UpdatePassword from "../pages/UpdatePassword";
import Dashboard from "../pages/Dashboard";
import ArchivedCars from "../pages/ArchivedCars";
import Profile from "../pages/Profile";
import AddNewCar from "../pages/AddNewCar";
import useAuth from "../hooks/useAuth";
import VerifyEmail from "../../src/pages/VerifyEmail";
import DealerSignup from "../pages/DealerSignup";
import Notifications from "../pages/Notifications";

import { doPost } from "../utils/apiCalls";
import { toast } from "react-toastify";
import { NewOffers } from "../pages/offers/newcars/index";
import OfferInfo from "../pages/offers/details/info";
import Reviews from "../pages/offers/details/reviews";
import Chat from "../components/caht/chat";
import Reports from "../pages/Reports/Reports";

const LoggedInRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/fraud-disclaimer" element={<FraudDisclaimer />} />
      <Route path="/frequently-asked-questions" element={<FAQ />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/archived-cars" element={<ArchivedCars />} />
      <Route path="/profile-settings" element={<Profile />} />
      <Route path="/add-new-car" element={<AddNewCar />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/view-offers/:id" element={<NewOffers />} />
      <Route path="/offers-details/info/:id" element={<OfferInfo />} />
      <Route path="/offers-details/reviews/:id" element={<Reviews />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/verify/:slug" element={<VerifyEmail />} />
      <Route path="*" element={<Home />} />
    </Route>
  </Routes>
);

const NotLoggedInRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="/login" element={<CustomerLogin />} />
      <Route path="/login" element={<CustomerLogin />} />
      <Route path="/dealer-sign-in" element={<DealerLogin />} />
      <Route path="/buyer-sign-up" element={<BuyerSignup />} />
      <Route path="/dealer-sign-up" element={<DealerSignup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verification-code" element={<VerificationCode />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/fraud-disclaimer" element={<FraudDisclaimer />} />
      <Route path="/frequently-asked-questions" element={<FAQ />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/verify/:slug" element={<VerifyEmail />} />
      <Route path="/add-new-car" element={<AddNewCar />} />
      <Route path="*" element={<CustomerLogin />} />
    </Route>
  </Routes>
);

const MainRouting = () => {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  useEffect(() => {
    setInterval(() => {
      handleLogin();
    }, 5000);
  }, []);

  const handleLogin = async () => {
    const prev = localStorage.getItem("CARCOMAUTH");
    let token_data = JSON.parse(prev);
    if (token_data != null) {
      const payLoad = { token: token_data?.token };
      const { isError, data } = await doPost(payLoad, "auth/check_login");
      if (data.error == "Invalid login credentials") {
        localStorage.removeItem("CARCOMAUTH");
        setLoggedObject(null);
        toast.error("Your session has been expired!");
        window.location.href = "/";
      }
    }
  };

  return (
    <Router>{isLoggedIn ? <LoggedInRoutes /> : <NotLoggedInRoutes />}</Router>
  );
};
export default MainRouting;

// };
