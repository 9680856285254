import React, { useState, useEffect } from "react";
import "./faqContent.scss";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";
const FAQContent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const [Data, setData] = useState([]);

  const [Loading, setLoading] = useState(false);

  const GetStaticpagesInfo = async () => {
    const payload = {
      // slug: "frequently-asked-questions",
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, `Auth/getAllFaqs`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetStaticpagesInfo();
  }, []);

  return (
    <div className="faq-wrapper-main">
      <div className="faq-wrapper">
        <ul className="list-style-none">
          {Data?.length > 0
            ? Data.map((item, index) => (
                <li
                  className={`faq-container ${
                    activeIndex === index && "faq-container-color"
                  }`}
                  key={index}
                >
                  <h2
                    onClick={() => toggleAnswer(index)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {item.title}
                    {activeIndex === index ? (
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    )}
                  </h2>
                  {activeIndex === index && (
                    <p dangerouslySetInnerHTML={{ __html: item.en }} />
                  )}
                </li>
              ))
            : "Loading..."}
        </ul>
      </div>
    </div>
  );
};

export default FAQContent;
