import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setCarOffers, setusercarID } from "../../lib/Redux/slices/webData";

const Offers = ({ children }) => {
  const Data = useSelector((state) => state.web);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const getData = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "Auth/getOfferDetails");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.message == "success") {
        setData(data);
        dispatch(setCarOffers(data?.car_offers));
        dispatch(setusercarID(data?.user_car_id));
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  return (
    <>
      <div className="w-[95%] m-auto pt-10">
        <p>
          <Link to={"/dashboard"}>
            <span className="underline cursor-pointer">My Cars</span>{" "}
          </Link>
          <span>{`>`}</span> <span>Audi Cars</span>{" "}
        </p>
        <div className="my-5">
          <div className="w-full grid sm:grid-cols-2 grid-cols-1 sm:gap-0 gap-5">
            <div className="flex items-center">
              <div className="2xl:w-1/2 lg:w-3/4 space-y-2">
                <h1 className="text-xl font-bold">
                  {data?.user_car_info?.title}
                </h1>
                <p>
                  {data.length === 0 ? (
                    "loading...."
                  ) : (
                    <>
                      {`${data?.user_car_info?.trime_val} - ${data?.user_car_info?.trim}`}{" "}
                      <br />
                      {`${data?.user_car_info?.engine_1}, ${data?.user_car_info?.engine_petrol}`}
                    </>
                  )}
                </p>
                {/* <button className="text-xl font-bold py-2 w-3/4 hover:bg-green-900 duration-100 hover:border-white hover:text-white rounded-md border-2 border-[#00000091]">
                  Edit this car
                </button> */}
              </div>
            </div>
            <div className="flex justify-end">
              <div className="2xl:w-3/5">
                <img
                  src={data?.user_car_info?.image}
                  className="w-full h-auto"
                  alt="Car.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid 2xl:grid-cols-8 sm:grid-cols-2 grid-cols-1 gap-5">
          <div
            className={`py-4 w-full text-lg border-b-2 flex items-center justify-center text-black border-black `}
          >
            {data?.car_type == "new" ? "New Cars" : "New used cars"}
          </div>
        </div>
      </div>
      <div className="py-10 bg-gray-200 w-full border-t border-[#4747477c]">
        <div className="w-[95%] m-auto">{children}</div>
      </div>
    </>
  );
};

export default Offers;
