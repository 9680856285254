import React from "react";
import "./services.scss";

const Services = () => {
  return (
    <div className="services-wrapper py-5">
      <h4>Varför CarCompare?</h4>
      <p className="sub-heading">
        Få det lägsta priset hemifrån, jämför i lugn och ro
      </p>
      <div className="services-card">
        <div className="card-container">
          <i class="fa fa-handshake-o" aria-hidden="true"></i>
          <p>Gratis</p>
          <span>Denna tjänst är kostnadsfri </span>
        </div>
        <div className="card-container">
          <i class="fa fa-file-text" aria-hidden="true"></i>
          <p>Bekvämlighet</p>
          <span>
            Du gör dina val på egen hand och bestämmer själv vilken handlare som
            passar dig. Handlaren står fast i sitt anbud under bestämd tid.
          </span>
        </div>
        <div className="card-container">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p>Säker</p>
          <span>
            Flertal auktoriserade handlare att köpa ifrån med ett par klick
          </span>
        </div>
      </div>
    </div>
  );
};

export default Services;
