import { Outlet, useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { LoginHeader } from "../components/header/loginHeader";
import MainRouting from "./MainRouting";
import useAuth from "../hooks/useAuth";
import { doPost } from "../utils/apiCalls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      setLoading(false);
    } else {
      navigate("/login");
    }
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     handleLogin();
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      {isLoggedIn ? <LoginHeader /> : <Header />}

      {/* <LoginHeader /> */}
      <div className="mt-14">
        <Outlet />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className="toast_class"
      />
      <Footer />
    </>
  );
};

export default Layout;
