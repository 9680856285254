import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

function VerifyEmail() {
    const navigate = useNavigate();

    const path = window.location.pathname;
    const lastSlashIndex = path.lastIndexOf('/');
    const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : '';

    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [slug, setSlug] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        const lastSlashIndex = path.lastIndexOf('/');
        const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : '';
        if(slugFromUrl != ""){
            setSlug(slugFromUrl);
            verify_user_account(slugFromUrl)
        } else {
            setSlug("not");
        }
      }, []);

   
    const verify_user_account = async (sllug) => {
        const payload = {
            slug:sllug,
        }
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/do_check_verify');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("Your account has been verifeid successfully!");
                navigate("/");
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
    };

  return (
    <div className="login-form-wrapper">
        <div className="w50 margin_auto">
            <div className="card p40">
                <div className="login-container">
                    Please wait we're verifying your account!
                </div>
            </div>
        </div>
    </div>
  );
}

export default VerifyEmail;
