import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import AboutUsContent from "../components/aboutUs/aboutUsContent";

const AboutUs = () => {
  return (
    <>
      <SubHeader subTitle="Om Oss" />
      <AboutUsContent />
    </>
  );
};

export default AboutUs;
