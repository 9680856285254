import React, { useEffect, useState } from "react";
import BannerSlider from "../components/home/banner/banner";
import Reviews from "../components/home/reviews/reviews";
import Services from "../components/home/services/services";
import HowItWorks from "../components/home/howItWorks/howItWorks";
import Recommended from "../components/home/recommended/recommended";
import NewsSubscriptionModal from "../components/modals/newsSubscriptionModal";

const Home = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const modalShown = localStorage.getItem("modalShown");
    if (!modalShown) {
      setOpenModal(true);
      localStorage.setItem("modalShown", "true");
    }
  }, []);

  return (
    <>
      <BannerSlider />
      <HowItWorks />
      <Services />
      <Reviews />
      <Recommended />

      {openModal && <NewsSubscriptionModal setModalClick={handleModalClose} />}
    </>
  );
};

export default Home;
