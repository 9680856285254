import React from "react";
import OfferDetailLayout from ".";
import { RiStarSFill } from "react-icons/ri";
import { useSelector } from "react-redux";

const ReviewsArr = [
  {
    name: "Hassan",
    desp: "Great Service !",
    date: "15 June 2024",
    Totalrating: 5,
    scorerating: 5,
    persentage: 88,
  },
  {
    name: "shohaib",
    date: "08 August 2023",
    desp: "Good",
    Totalrating: 4,
    scorerating: 4,
    persentage: 70,
  },
  {
    name: "Asif",
    date: "10 December 2021",
    desp: "Modrate",
    Totalrating: 3,
    scorerating: 3,
    persentage: 60,
  },
  {
    name: "ALi",
    date: "01 April 2020",
    desp: "poor",
    Totalrating: 2,
    scorerating: 2,
    persentage: 40,
  },
  {
    name: "Awais",
    date: "20 February 2019",
    desp: "Poor Service",
    Totalrating: 1,
    scorerating: 1,
    persentage: 20,
  },
];

const Reviews = () => {
  const { dealerData } = useSelector((state) => state.web);
  const { delar_info = {}, reviews = [] } = dealerData;
  const ReviewsArr = reviews;

  const starCount = 5;

  const renderStars = (avg) => {
    let roundedAverage = Math.round(avg);
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      if (i < roundedAverage) {
        stars.push(
          <RiStarSFill key={i} className="text-yellow-400 text-3xl" />
        );
      } else {
        stars.push(<RiStarSFill key={i} className="text-black text-3xl" />);
      }
    }
    return stars;
  };

  return (
    <OfferDetailLayout>
      <div className="xl:w-2/3 lg:w-3/4 m-auto">
        <h1 className="text-xl font-bold pb-5">
          Reviews from CarCompare buyers
        </h1>
        <div className="w-full grid md:grid-cols-2 grid-cols-1 md:gap-0 gap-y-5">
          <div className="space-y-2">
            <div className="flex">{renderStars(delar_info?.rating)}</div>
            <p>
              Rated{" "}
              <span className="font-semibold">
                {delar_info?.rating} / {5}
              </span>{" "}
              from <span className="font-semibold">{delar_info?.reviews}</span>{" "}
              reviews
            </p>
          </div>
          <div className="space-y-2">
            <h1 className="text-lg font-bold">Rating distribution</h1>
            {delar_info?.reviews_arr &&
              delar_info?.reviews_arr.map((rev, index) => {
                return (
                  <div key={index} className="w-full sm:text-lg font-semibold">
                    <div className="w-full flex items-center justify-between">
                      <span className="sm:w-[15%] w-[20%]">
                        {rev?.total_star} star
                      </span>
                      <div className="sm:w-[60%] w-[50%]">
                        <div
                          className={`h-[20px] w-full ${"bg-green-600"} `}
                        ></div>
                      </div>
                      <span className="sm:w-[15%] w-[20%]">
                        {rev?.star_percentage}%
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="w-full  mt-5">
          {ReviewsArr &&
            ReviewsArr.map((review, index) => {
              return (
                <div key={index} className="border-t grid grid-cols-2 py-5">
                  <h1 className="text-xl font-medium">{review?.user_name}</h1>
                  <div className="flex justify-end">
                    <div className="flex flex-col items-end space-y-2">
                      <div className="flex justify-end">
                        {renderStars(review?.rating)}
                      </div>
                      <p className="text-xs text-gray-500">{review?.date}</p>
                    </div>
                  </div>
                  <p className="text-sm font-normal">{review?.reviews}</p>
                </div>
              );
            })}
        </div>
      </div>
    </OfferDetailLayout>
  );
};

export default Reviews;
