import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Tokenvalue: 0,
  CarOffers: [],
  userCarID: 0,
  dealerId: 0,
  dealerData: [],
};

const webDataSlice = createSlice({
  name: "WebData",
  initialState,
  reducers: {
    setCarOffers: (state, action) => {
      state.CarOffers = action.payload;
    },
    setUsednewcars: (state, action) => {
      state.usedcars = action.payload;
    },
    setusercarID: (state, action) => {
      state.userCarID = action.payload;
    },
    setDealerID: (state, action) => {
      state.dealerId = action.payload;
    },
    setDealerData: (state, action) => {
      state.dealerData = action.payload;
    },
  },
});

export const { setCarOffers, setusercarID, setDealerID, setDealerData } =
  webDataSlice.actions;
export const selectWebData = (state) => state.WebData.value;
export default webDataSlice.reducer;
