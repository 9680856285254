import React, { useState, useEffect } from "react";

import useAuth from "../hooks/useAuth";
import { doPost } from "../utils/apiCalls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [type, setType] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setType(getLoggedObject()?.user_type);
    get_notif_data();
  }, []);

  const get_notif_data = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_notif_data");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_read_notif = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val?.id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/read_notif");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        get_notif_data();
        navigate(val?.redirect_link);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="mycars-main py-10">
        <div className="mycars-wrapper">
          <div className="mycars-heading-container">
            <h4>meddelande</h4>
          </div>

          <div className="">
            {data.length == 0 && !loading && (
              <>
                <div className="no_car my-3">
                  <div className="no_car_text cst_car wd100">
                    No notifications found!
                  </div>
                </div>
              </>
            )}
            {data.map((v, i) => (
              <>
                <div
                  className="notif_wor my-2 cursor-pointer"
                  onClick={() => do_read_notif(v)}
                >
                  <div
                    className="notif_text"
                    dangerouslySetInnerHTML={{ __html: v.title }}
                  ></div>
                  <div className="notif_date" style={{ color: "grey" }}>
                    {v.date}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
