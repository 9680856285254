import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import VerificationCodeForm from "../components/forgotPassword/verificationCodeForm/verificationCodeForm";

const VerificationCode = () => {
  return (
    <>
      <SubHeader subTitle="Verification Code" />
      <VerificationCodeForm />
    </>
  );
};

export default VerificationCode;
