import React, { useEffect, useState, useRef } from "react";
import "../buyerSignup.scss";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

const BuyerSignupForm = () => {

  const {getLoggedObject, setLoggedObject, isLoggedIn, checkLogin} = useAuth();
    const navigate = useNavigate();
    const [fullname, setFullName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const [data, setData] = useState([]);
    const [region, setRegion] = useState([]);
    const [regionselect, setRegionSelect] = useState(0);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [authorized, setAuthorized] = useState(0);
    const [used, setUsed] = useState(0);
    const [brand, setBrand] = useState(0);
    const [website, setWebsite] = useState('');

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const isValidPassword = newPassword.length >= 6 && /[A-Z]/.test(newPassword);
        setIsValid(isValidPassword);
    };

    const handleLogin = async () => {
        setLoading(true);
        const logged = await checkLogin();
        if(logged){
            navigate('/dashboard');
        }else{
            setLoading(false);
        }
      }

    useEffect(()=>{
        if(isLoggedIn){
            handleLogin();
            get_car_makes();
        }
    },[isLoggedIn])

    useEffect(()=>{
        get_car_makes();
        get_all_regions()
    },[])

    const get_all_regions = async () => {
        const payload = {
          car: 1,
        };
        setLoading(true);
        const { isError, data } = await doPost(payload, "auth/get_sweden_regions");
        if (isError) {
            setRegion([]);
          toast.error("Something went wrong with server.");
          setLoading(false);
        } else {
          if (data.action == "success") {
            setLoading(false);
            setRegion(data?.data);
          } else {
            setLoading(false);
            toast.error(data?.error);
          }
        }
      };

    const get_car_makes = async () => {
        const payload = {
          token: getLoggedObject()?.token,
        };
        setLoading(true);
        const { isError, data } = await doPost(payload, "auth/get_all_cars");
        if (isError) {
          setData([]);
          toast.error("Something went wrong with server.");
          setLoading(false);
        } else {
          if (data.action == "success") {
            setLoading(false);
            setData(data?.data);
          } else {
            setLoading(false);
            toast.error(data?.error);
          }
        }
    };

    const do_signup = async () => {
        if(fullname==""){
            toast.error("Please enter company name!");
            return;
        }
        if(email==""){
            toast.error("Please enter email address!");
            return;
        }
        if(phone==""){
            toast.error("Please enter phone number!");
            return;
        }
        if(authorized==0){
            toast.error("Please select authorized dealer?");
            return;
        }
        if(selectedItems.length==0){
            toast.error("Please select new/used car?");
            return;
        }
        if(selectedItems.length==0){
            toast.error("Please select brand name!");
            return;
        }
        if(address==""){
            toast.error("Please enter address!");
            return;
        }
        if(regionselect==0){
            toast.error("Please select region!");
            return;
        }
        if(password==""){
            toast.error("Please enter your pasword!");
            return;
        }
        const payload = {
            fullname:fullname,
            email:email,
            password:password,
            phone:phone,
            authorized:authorized,
            used:selectedItemsnew,
            brand:selectedItems,
            address:address,
            region:regionselect,
            website:website,
            password:password,
        }
        // console.log(payload);
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/dealer_sigmup');
        console.log(data);
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("Your account has been created successfully! Our team will check and connect with you through phone!");
                navigate("/dealer-sign-in");
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
    };

    const [selectedItems, setSelectedItems] = useState([]);

    const handleBrandChange = (e) => {
        const selectedBrandId = e.target.value;
        const selectedBrandName = e.target.options[e.target.selectedIndex].text;
        setSelectedItems([...selectedItems, { id: selectedBrandId, title: selectedBrandName }]);
        e.target.options[e.target.selectedIndex].disabled = true;
    };

    const delete_pill = (itemToDelete) => {
        const updatedSelectedItems = selectedItems.filter(item => item.id !== itemToDelete.id);
        const selectElement = document.querySelector('select[name="spealized"]');
        const optionToRemoveDisabled = selectElement.querySelector(`option[value="${itemToDelete.id}"]`);
        if (optionToRemoveDisabled) {
            optionToRemoveDisabled.disabled = false;
        }
        setSelectedItems(updatedSelectedItems);
    };


    const [selectedItemsnew, setSelectedItemsNew] = useState([]);

    const handleBrandChangeNew = (e) => {
        const selectedBrandId = e.target.value;
        const selectedBrandName = e.target.options[e.target.selectedIndex].text;
        setSelectedItemsNew([...selectedItemsnew, { id: selectedBrandId, title: selectedBrandName }]);
        e.target.options[e.target.selectedIndex].disabled = true;
    };

    const delete_pillNew = (itemToDelete) => {
        const updatedSelectedItems = selectedItemsnew.filter(item => item.id !== itemToDelete.id);
        const selectElement = document.querySelector('select[name="sell"]');
        const optionToRemoveDisabled = selectElement.querySelector(`option[value="${itemToDelete.id}"]`);
        if (optionToRemoveDisabled) {
            optionToRemoveDisabled.disabled = false;
        }
        setSelectedItemsNew(updatedSelectedItems);
    };
  return (
    <div className="login-form-wrapper">
        <p>
            These little boxes are all that stand between you and success. <br />Once you've filled them in, a member of our team will be in touch.
        </p>
        {/* <form onSubmit={()=> do_signup()} method="post"> */}
        <div className="custom_wrap_login">
            <div className="form_inp_signup">
                <label>Email Address</label>
                <input
                    className="form-input login-field"
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label>Company name</label>
                <input
                    className="form-input login-field"
                    placeholder="Company name"
                    type="text"
                    value={fullname}
                    required
                    onChange={(e) => setFullName(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label>Phone Number</label>
                <input
                    className="form-input login-field"
                    placeholder="Phone Number"
                    type="number"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label> Are you authorized dealer?</label>
                <select name="authorized" required className="form-input login-field" value={authorized} onChange={(e)=>setAuthorized(e.target.value)}>
                    <option value="0">-- Authorized Dealer--</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                </select>
            </div>
            <div className="form_inp_signup">
                <label> Sell new or used car?</label>
                <select name="sell" required className="form-input login-field" value={used} onChange={(e)=>handleBrandChangeNew(e)}>
                    <option value="0">--New/Used cars--</option>
                    <option value="1" disabled={selectedItemsnew.find(item => item.id === 1)}>New</option>
                    <option value="2" disabled={selectedItemsnew.find(item => item.id === 2)}>Used</option>
                </select>

                <>
                {
                    selectedItemsnew.length > 0 &&
                    <div className="pills_wrap_flex">
                    {selectedItemsnew.map((v, i)=>(
                        <>
                            <div className="pills_user_cars">
                                <div className="">{v.title}</div>
                                <div className="cross_" style={{cursor:"pointer"}} onClick={()=> delete_pillNew(v)}>x</div>
                            </div>
                        </>
                    ))}
                    </div>
                }
                </>
            </div>
            <div className="form_inp_signup">
                <label>Specialized in any car brand?</label>
                <select name="spealized" required className="form-input login-field" value={brand} onChange={(e)=>handleBrandChange(e)}>
                    <option value="0">--Choose Brand--</option>
                    {data.map((v, i)=>(
                        <>
                            <option value={v.id} disabled={selectedItems.find(item => item.id === v.id)}>{v.title}</option>
                        </>
                    ))}
                </select>

                <>
                {
                    selectedItems.length > 0 &&
                    <div className="pills_wrap_flex">
                    {selectedItems.map((v, i)=>(
                        <>
                            <div className="pills_user_cars">
                                <div className="">{v.title}</div>
                                <div className="cross_" style={{cursor:"pointer"}} onClick={()=> delete_pill(v)}>x</div>
                            </div>
                        </>
                    ))}
                    </div>
                }
                </>
            </div>
            
            <div className="form_inp_signup">
                <label>Address</label>
                <input
                    className="form-input login-field"
                    placeholder="Address"
                    type="text"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
            </div>

            <div className="form_inp_signup">
                <label>Region</label>
                <select name="spealized" required className="form-input login-field" value={regionselect} onChange={(e)=>setRegionSelect(e.target.value)}>
                    <option value="0">--Choose Region--</option>
                    {region.map((v, i)=>(
                        <>
                            <option value={v.id}>{v.title}</option>
                        </>
                    ))}
                </select>
            </div>

            <div className="form_inp_signup">
                <label>Website</label>
                <input
                    className="form-input login-field"
                    placeholder="Website URL"
                    type="url"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
            </div>

            <div className="form_inp_signup">
                <label>Password</label>
                <input
                    className="form-input login-field"
                    placeholder="Password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <button className="login-btn" disabled={loading} onClick={()=>do_signup()}>Apply Now</button>
            <p className="have-account text-center mobiles_small" style={{width:"100%", textAlign:"center"}}>
                Already have an dealer account? <a href="/dealer-sign-in">Login</a>
            </p>
        </div>
      {/* </form> */}
    </div>
  );
};

export default BuyerSignupForm;
