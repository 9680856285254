import React from "react";
import OfferDetailLayout from ".";
import { FaCircleXmark, FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Audi from "../../../assets/images/audi-logo.png";
import { useSelector } from "react-redux";

const cars = ["", "", "", "", ""];

const OfferInfo = () => {
  const currentDay = new Date().toLocaleString("en-us", { weekday: "long" });
  const data = useSelector((state) => state.web.dealerData);
  const { delar_info = {}, delar_cars = [] } = data;

  return (
    <OfferDetailLayout>
      <div className="py-10 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-y-5">
        <div className="col-span-2">
          <div className="space-y-4">
            <div className="space-y-4">
              <h1 className="flex text-lg font-medium">
                <span>Authorized</span>
                <span className="flex items-center px-2">
                  {delar_info?.authorized == "1" ? (
                    <FaCircleCheck className="text-green-600 text-base" />
                  ) : (
                    <FaCircleXmark className="text-red-600 text-base" />
                  )}
                </span>
              </h1>
            </div>
            <div className="space-y-4">
              <h1 className="flex text-lg font-medium">
                <span>Sell car type :</span>
                <span className="flex items-center px-2 text-base font-normal">
                  {delar_info?.used ? "New" : "New used"}
                </span>
              </h1>
              <div className="space-y-4">Choose A Type of "1 Series"
                <h1 className="flex text-lg font-medium">
                  <span>Region :</span>
                  <span className="flex items-center px-2 text-base font-normal">
                    {delar_info?.region_title}
                  </span>
                </h1>
                <h1 className="flex text-lg font-medium">
                  <span>Address :</span>
                  <span className="flex items-center px-2 text-base font-normal">
                    {delar_info?.address}
                  </span>
                </h1>
                {delar_info?.website !== "" && (
                  <h1 className="flex text-lg font-medium">
                    <span>Website :</span>
                    <span className="flex items-center px-2 text-base font-normal ">
                      <Link
                        className="text-blue-500 hover:underline"
                        to={delar_info?.website}
                        target="_blank"
                      >
                        {delar_info?.website}
                      </Link>
                    </span>
                  </h1>
                )}
              </div>
            </div>
            <div className="space-y-4">
              <h1 className="text-xl font-medium">Om Oss</h1>
              <p className="sm:text-base font-normal">{delar_info?.about}</p>
            </div>
          </div>
        </div>
        <div className="sm:col-span-1 col-span-2 space-y-4 lg:pl-3">
          <h1 className="text-xl font-bold">Opening Hours</h1>
          <div className="space-y-2">
            {delar_info?.timings &&
              delar_info?.timings.map((day, index) => {
                return (
                  <p
                    key={index}
                    className={`flex ${
                      day.day === currentDay ? "font-bold" : ""
                    }`}
                  >
                    <span className="w-[120px] block">{day.day} </span>
                    <span>
                      {day.start} - {day.end}
                    </span>
                  </p>
                );
              })}
          </div>
        </div>
        <div className="space-y-4 col-span-3 py-4">
          <h1 className="text-xl font-bold">Cars</h1>
          <div className="grid w-full xl:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-5">
            {delar_cars &&
              delar_cars.map((car) => {
                return (
                  <div
                    key={car?.id}
                    className={`car_boxes_makes small_Cars w-full`}
                  >
                    <img src={car?.image} />
                    <div className="">{car?.title}</div>
                    <div className="hybrid_electric">
                      <div className="hybrid_pill">
                        {car?.hybrid == "1" ? "Hybrid" : "Manual"}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </OfferDetailLayout>
  );
};

export default OfferInfo;
