import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/global.scss";
import "./assets/app.css";
import "./assets/scss/font-awesome.css";
import MainRouting from "./layout/MainRouting";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import ReduxProvider from "./lib/Redux/Reduxprovider";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <GoogleOAuthProvider clientId="431601297091-rn26euiflghbbp5tr3me9bt6aknha4p4.apps.googleusercontent.com">
      <ReduxProvider>
        <MainRouting />
      </ReduxProvider>
    </GoogleOAuthProvider>
  </>
);
