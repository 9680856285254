import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import DealerSignup from "../components/signup/buyerSignup/DealerSignup";

const BuyerSignup = () => {
  return (
    <>
      <SubHeader subTitle="Partner Signup" />
      <DealerSignup />
    </>
  );
};

export default BuyerSignup;
