import React, { useState } from "react";
import "./addNewCar.scss";

const ChooseType = ({ carComponents, setCarComponents }) => {
  const carOptions = [
    { label: "Ny", value: "new" },
    { label: "Ny och Begagnad", value: "newAndUsed" },
    { label: "Used", value: "used" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div className="addNewCar-main">
      <div className="addNewCar-wrapper ">
        <div className="addNewCar-heading-container">
          <h4>Choose A Type To Get Prices For</h4>
          <div className="btn-container">
            <button
              className="addNewCar-build-new-car-btn"
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  chooseMake: false,
                  chooseModel: true,
                })
              }
            >
              Back
            </button>
            <button
              className={`addNewCar-build-new-car-btn ${
                selectedOption ? "" : "disabled"
              }`}
              disabled={!selectedOption}
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  choosePaymentMethod: true,
                  chooseType: false,
                })
              }
            >
              Next
            </button>
          </div>
        </div>

        <div className="type-options-container">
          {carOptions.map((option, index) => (
            <div
              key={index}
              className={`type-option ${
                selectedOption === option.value ? "selected" : ""
              }`}
              onClick={() => setSelectedOption(option.value)}
            >
              <input
                type="radio"
                name="carOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => setSelectedOption(option.value)}
              />
              <p>{option.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseType;
