import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import ContactUsContent from "../components/contactUs/contactUsContent";

const ContactUs = () => {
  return (
    <>
      <SubHeader subTitle=" Kontakta Oss" />
      <ContactUsContent />
    </>
  );
};

export default ContactUs;
