import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import TermsConditionsContent from "../components/termsConditions/termsConditions";

const TermsConditions = () => {
  return (
    <>
      <SubHeader subTitle="Villkor" />
      <TermsConditionsContent />
    </>
  );
};

export default TermsConditions;
