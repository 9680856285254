import React, { useEffect, useState } from "react";
import "./contactUsContent.scss";
import GoogleMap from "./maps";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";

const ContactUsContent = () => {
  const [Data, setData] = useState({});
  console.log("🚀 ~ ContactUsContent ~ Data:", Data);
  const [Loading, setLoading] = useState(false);

  const GetStaticpagesInfo = async () => {
    const payload = {};
    setLoading(true);
    const { isError, data } = await doPost(payload, `Auth/getSettings`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetStaticpagesInfo();
  }, []);

  return (
    <div className="contact-us-wrapper">
      {/* <h3>Get In Touch</h3> */}
      <div className="contact-us-container">
        <div className="contact-us-content">
          <input className="form-input input-field" placeholder="Namn, Efternamn
" />
          <input
            className="form-input input-field"
            placeholder="Email Address"
          />
          <input
            className="form-input input-field"
            placeholder=" Mobilnummer "
          />
          <input className="form-input input-field" placeholder="Ämne" />
          <textarea
            className="form-input input-field"
            placeholder="Meddelande"
            rows={4}
          />

          <button className="banner-btn">Send Message</button>
        </div>
        {Loading ? (
          "Loading..."
        ) : (
          <div className="contact-us-image">
            <div className="contact-us-info">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <p>{Data?.mobile}</p>
            </div>
            <div className="contact-us-info">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <p>{Data?.email}</p>
            </div>
            <div className="contact-us-info">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <p>{Data?.address}</p>
            </div>
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsContent;
