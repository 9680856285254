export const urls = {
  // API: "https://dedevelopers.org/carcomp/",
  // PHP_API: "https://dedevelopers.org/carcomp/",

  // API: "https://3f80-119-155-24-49.ngrok-free.app/CarCompare-Backend/",
  // PHP_API: "https://3f80-119-155-24-49.ngrok-free.app/CarCompare-Backend/",

  // API: "http://192.168.1.39/CarCompare-Backend/",
  // PHP_API: "http://192.168.1.39/CarCompare-Backend/",

  // API: "http://192.168.18.80/carcompare/",
  // PHP_API: "http://192.168.18.80/carcompare/",

  // API: "http://192.168.10.14/carcompare/",
  // PHP_API: "http://192.168.10.14/carcompare/",

  // -------------------------------  Live Link of Backend  -------------------------------

  API: "https://api.carcompare.se/",
  PHP_API: "https://api.carcompare.se/",
};
