import "./modals.scss";
import CrossIcon from "../../assets/images/CrossIcon.png";
import CarImage from "../../assets/images/car-news.jpg";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";

const NewsSubscriptionModal = (props) => {
  const [SubEmail, setSubEmail] = useState("");

  const [Loading, setLoading] = useState(false);
  const { getLoggedObject } = useAuth();
  const [popupInfo, setPopupInfo] = useState({});

  const SubscribeEmail = async () => {
    if (SubEmail == "") {
      toast.error("Please enter your email address!");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(SubEmail)) {
      toast.error("Please enter a valid email address!");
      return;
    }

    const payload = {
      token: getLoggedObject()?.token,
      email: SubEmail,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, `Auth/saveEmail`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        toast.success("Thank you for subscribing");
        localStorage.setItem("modalShown", "false");
        props.setModalClick(false);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  const handlegetinfo = async () => {
    const { isError, data } = await doPost("", `Auth/popupInfo`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setPopupInfo(data?.data);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    handlegetinfo();
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="Video-modal-dialog">
        <div className="modal-content">
          <div className="home-video-container">
            <div className="image">
              <img src={popupInfo?.image_pop_up} alt="Car" />
            </div>
            <div className="form-container">
              <h4>{popupInfo?.heading_pop_up}</h4>
              <h5>{popupInfo?.text_pop_up}</h5>
              <div className="field-container">
                <input
                  className="form-input input-field"
                  placeholder="Enter your Email Address"
                  type="email"
                  value={SubEmail}
                  onChange={(e) => setSubEmail(e.target.value)}
                />
                <button
                  disabled={Loading}
                  onClick={SubscribeEmail}
                  className="banner-btn"
                >
                  {Loading ? "Loading..." : "Subscribe"}
                </button>
              </div>
              <h6 onClick={props.setModalClick}>
                No Thanks, I'm not interested{" "}
              </h6>
            </div>
            <img
              onClick={props.setModalClick}
              className="cross-icon"
              src={CrossIcon}
              alt={CrossIcon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSubscriptionModal;
