import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import ForgotPasswordForm from "../components/forgotPassword/forgotPasswordForm/forgotPasswordForm";

const ForgotPassword = () => {
  return (
    <>
      <SubHeader subTitle="Forgot Password" />
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
