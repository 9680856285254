import React from "react";
import "./aboutUsContent.scss";
import AboutImage1 from "../../assets/images/about-us.jpg";
import AboutImage2 from "../../assets/images/about-us1.jpg";

const AboutUsContent = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-us-container">
        <div className="about-us-content">
          <h2>Vi är två</h2>
          <p>
            barndomsvänner som delar en lång historia och en gemensam passion
            för bilar. Vår resa började i en mindre stad i Skåne, där vi växte
            upp tillsammans och delade samma skolbänk från mellanstadiet ända
            till studenten. Genom åren har vår nyfikenhet och vårt intresse för
            bilindustrin bara växt, och det var just dessa gemensamma intressen
            som la grunden till vår idé - "Car Compare".
          </p>
        </div>
        <div className="about-us-image">
          <img src={AboutImage1} alt="About" />
        </div>
      </div>

      <div className="about-us-container-second">
        <div className="about-us-image-1">
          <img src={AboutImage2} alt="About" />
        </div>
        <div className="about-us-content-second">
          <h2>Med en stark </h2>
          <p>
            vision och en tydlig plan började vi vår resa för att skapa något
            unikt. Efter mycket planering, hårt arbete och rätt mindset, kan vi
            idag stolt presentera vår färdiga produkt. Som utbildade ekonomer
            har vi kombinerat våra kunskaper och erfarenheter för att skapa en
            tjänst som förenklar valet av bil för våra kunder.
          </p>
          <p>Välkommen till Car Compare - din partner i bilköpet!</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsContent;
