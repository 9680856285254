import React, { useEffect, useState } from "react";
import "./recommended.scss";
import { toast } from "react-toastify";
import { doPost } from "../../../utils/apiCalls";

const Recommended = () => {
  const [Loading, setLoading] = useState(false);
  const [RecomendendData, setRecomendendData] = useState([]);

  const handleGetRecomended = async () => {
    setLoading(true);
    const { isError, data } = await doPost({}, "auth/getRecommendedCarModels");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.message === "success") {
        setLoading(false);
        setRecomendendData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    handleGetRecomended();
  }, []);

  return (
    <div className="recommended-main">
      <div className="recommended-wrapper space-y-6">
        <h4>Rekommenderade bilar 
        </h4>
        <div className="car-type">
          {Loading && "Loading..."}
          {RecomendendData.length > 0
            ? RecomendendData.map((data, index) => {
                return (
                  <div className="car-cards" key={index}>
                    <div className="h-1/2">
                      <img
                        className="h-auto w-full object-cover"
                        src={data.image}
                        alt="Car"
                      />
                    </div>
                    <p className="car-name">{data.title}</p>
                    <p className="car-intro">{data.intro}</p>
                    <div className="divider" />
                    <p className="new-from">Nypris från</p>
                    <p className="price"> {data.starting_price}</p>
                  </div>
                );
              })
            : "No Data Found"}
        </div>
      </div>
    </div>
  );
};

export default Recommended;
