import React, { useState } from "react";
import { RiStarSFill } from "react-icons/ri";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaClock } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Reviews from "../review/reviews";
import { useDispatch } from "react-redux";
import { setDealerID } from "../../lib/Redux/slices/webData";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { doPost } from "../../utils/apiCalls";

const Caroffer = ({
  header = "",
  Totalreviws = "",
  Price = 0,
  Save = "",
  date = "",
  color = "",
  persent = "",
  colorname = "",
  average = "",
  offerId = "",
  did = "",
  pcpprice = 0,
  miles = null,
  leasingPrice = 0,
}) => {
  const [popup, setpopup] = useState(false);

  const roundedAverage = Math.round(average);
  const starCount = 5;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      if (i < roundedAverage) {
        stars.push(
          <RiStarSFill
            key={i}
            className="text-yellow-400 sm:text-3xl text-2xl"
          />
        );
      } else {
        stars.push(
          <RiStarSFill key={i} className="text-black sm:text-3xl text-2xl" />
        );
      }
    }
    return stars;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handledealerDetails = () => {
    navigate(`/offers-details/info/${did}`);
    dispatch(setDealerID(did));
  };

  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const Createconservation = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      delar_id: did,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      `Auth/saveConversation    `
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        console.log(data);
        navigate(`/chat`);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  return (
    <>
      <div className="w-full p-3 bg-white rounded-md grid lg:grid-cols-4 grid-cols-1 sm:gap-0 gap-y-6">
        <div className="space-y-3 col-span-1 lg:flex lg:flex-col lg:justify-center">
          <h2
            onClick={handledealerDetails}
            className="text-2xl font-bold cursor-pointer"
          >
            {header}
          </h2>
          <div className="flex items-center space-x-2 lg:mt-2">
            {renderStars()}
            <p className="sm:text-xl text-xs underline cursor-pointer">
              {Totalreviws} Recensioner
            </p>
          </div>
          <div className="flex items-center space-x-2 mt-2 lg:mt-0">
            <div
              style={{ backgroundColor: `${color}` }}
              className="h-[20px] w-[20px] border rounded-full"
            ></div>
            <p>{colorname}</p>
          </div>
          {miles != null && (
            <p className="text-[#272727] text-base font-semibold">
              Mileage : <span className="font-normal">{miles} km</span>
            </p>
          )}
        </div>
        <div className="flex flex-col lg:flex-row lg:col-span-3 lg:justify-end">
          <div className="flex items-center justify-center border-b sm:border-none sm:py-0 py-3 lg:w-1/4 w-full">
            <div className="space-y-2 px-2 flex flex-col justify-center items-center">
              <h1 className="text-base font-medium sm:text-center"> Sparande</h1>
              <p className="text-center font-bold 2xl:text-xl  text-green-400 flex items-center space-x-2">
                <span>SEK</span> <span>{Save}</span>{" "}
                <span className="text-base">({persent})</span>{" "}
              </p>
            </div>
          </div>
          {Price > 0 && (
            <div className="flex items-center justify-center border-b sm:border-none sm:py-0 py-3 lg:w-1/4 w-full">
              <div className="space-y-2 sm:border-l sm:border-r px-2 w-full">
                <h1 className="text-base font-medium text-center">Kontant</h1>
                <p className="text-center font-bold 2xl:text-xl">
                  <span>SEK</span> <span>{Price}</span>{" "}
                </p>
              </div>
            </div>
          )}
          {leasingPrice > 0 && (
            <div className="flex items-center justify-center border-b sm:border-none sm:py-0 py-3 lg:w-1/4 w-full">
              <div className="space-y-2 sm:border-l sm:border-r px-2 w-full">
                <h1 className="text-base font-medium text-center">Leasing</h1>
                <p className="text-center font-bold 2xl:text-xl">
                  <span>SEK</span> <span>{leasingPrice}</span>{" "}
                </p>
              </div>
            </div>
          )}
          {pcpprice > 0 && (
            <div className="flex items-center justify-center border-b sm:border-none sm:py-0 py-3 lg:w-1/4 w-full">
              <div className="space-y-2 sm:border-l sm:border-r px-2 w-full">
                <h1 className="text-base font-medium text-center">PCP</h1>
                <p className="text-center font-bold 2xl:text-xl">
                  <span>SEK</span> <span>{pcpprice}</span>{" "}
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-col space-y-2 sm:items-end items-center sm:py-0 py-3 lg:w-1/4 w-full">
            <div className="w-full relative">
              <button
                disabled={Loading}
                onClick={Createconservation}
                className="bg-teal-400 hover:bg-teal-600 text-white font-bold py-3 px-6 rounded w-full"
              >
                {Loading ? "Messaging..." : " Meddelande"}
              </button>
            </div>
            <button
              onClick={() => setpopup(true)}
              className="bg-transparent hover:bg-teal-300 text-blue-700 font-semibold hover:text-white py-3 px-6 w-full border border-teal-300 hover:border-transparent rounded"
            >
              Lägg till Recension{" "}
            </button>
            <p className="flex space-x-2 items-center justify-center text-red-600 w-full">
              <FaClock /> <span className="text-sm">Utågår efter {date}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`h-screen w-full bg-[#00000062] flex items-center justify-center fixed ${
          popup ? "left-0" : "-left-full"
        }   z-[999] top-0`}
      >
        <Reviews pop={setpopup} id={offerId} />
      </div>
    </>
  );
};

export default Caroffer;
