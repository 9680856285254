import React, { useEffect, useState, useRef } from "react";
import "../myCars/myCars.scss";
import CarImage from "../../../assets/images/carr.jpg";

import { useNavigate, Link } from "react-router-dom";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";

const ArchivedCarsComponent = () => {
  const CarArray = [
    {
      image: CarImage,
      name: "Mercedes-Benz",
      intro: "1.6T GDi 248 PHEV Auto AWD GT-Line",
      type: "Automatic, Hybrid",
      color: "Premium paint - Phantom black",
    },
  ];

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [delid, setDelID] = useState(0)
  const [options, setOptions] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      get_user_cars()
      setLoading(false);
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [isLoggedIn]);


  const get_user_cars = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      status: 2
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_user_all_cars");
    if (isError) {
      setData([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_archive = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      delid: delid
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/un_archive_user_car");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setPopUp(false)
        setDelID(0)
        toast.success("Your car status has been updated successfully!");
        get_user_cars();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  }

  return (
    <div className="mycars-main">
      <div className="mycars-wrapper">
        <div className="mycars-heading-container">
          <h4>arkiverade bilar</h4>
        </div>
        <div className="mycars-type">

          {
            (data.length == 0 && !loading) &&
            <>

              <div className="no_car">
                <div className="no_car_text">
                  No archived car found!
                </div>
                 
              </div>
            </>
          }

          {
            data.map((data, index) => {
              return (
                <div className="mycars-cards" key={index}>
                  <div className="relative">
                    <div className="icon_archive" onClick={() => {
                      setPopUp(true)
                      setDelID(data.id)
                    }}>
                      <i className="fa fa-trash-o"></i>
                    </div>
                    <img src={data.image} alt="Car" />
                  </div>
                  <p className="mycars-name">{data.title}</p>
                  <p className="mycars-intro">{data.trime_val} - {data.trim}</p>
                  <p className="mycars-intro">
                    <div className="car_into_flex">
                        <div className="">
                          {data.engine_1}, {data.engine_petrol} . 
                        </div>
                        <div className="optios_car" >
                          {data.additional_data.length > 0 && <div onClick={()=> setOptions(data.additional_data)}>{data.additional_data.length} Options </div> }
                        </div>
                      </div>
                  </p>
                  <div className="mycars-divider" />
                  <p className="mycars-color">
                    <div className="cirlce-colorr" style={{background:data.color_code}}></div>
                    {data.color}
                  </p>
                  <div className="mycars-btn-container">
                    <button className="mycars-view-specs-btn">View Offers</button>
                  </div>
                </div>
              );
            })
          }

{
            popup &&
          <>
            <div className="outer_image">
              <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{fontSize:"16px", marginBottom:"20px"}}>Un-Archive car?</h4>
                    <p className="small_pop_text">After un-archiving the car it will start displaying on your dashboard and you will start receiving offers again.
</p>

                    <div className="popup_flex">
                      <div style={{color:"red", cursor:"pointer"}} onClick={()=> setPopUp(false)}>
                          Cancel
                      </div>
                      <button className="new_car_add" onClick={()=> do_archive()}>
                          Confirm
                      </button>
                      
                    </div>
                  </div>
              </div>
            </div>
          </>
        } 

{
            options &&
          <>
            <div className="outer_image">
              <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{fontSize:"16px", marginBottom:"20px"}}>Additional Options</h4>

                      {
                        options.map((v, i)=>(
                          <>
                          <div className="box_price_pop">
                            <div className="">
                              {v.title}
                            </div>
                            <div className="price">
                              {v.price}
                            </div>
                          </div>
                          </>
                        ))
                      }
                    
                      <div style={{color:"red", cursor:"pointer", marginTop:"20px"}} onClick={()=> setOptions(false)}>
                          Close
                      </div>
                  </div>
              </div>
            </div>
          </>
        } 

          {/* {CarArray.map((data, index) => {
            return (
              <div className="mycars-cards" key={index}>
                <img src={data.image} alt="Car" />
                <p className="mycars-name">{data.name}</p>
                <p className="mycars-intro">{data.intro}</p>
                <p className="mycars-intro">{data.type}</p>
                <div className="mycars-divider" />
                <p className="mycars-color">{data.color}</p>
                <div className="mycars-btn-container">
                  <button className="mycars-view-specs-btn">View Offers</button>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default ArchivedCarsComponent;
