import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../forgotPassword.scss";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const do_action = async () => {
        if(email==""){
            toast.error('Please enter your email address!');
            return;
        }
        if(!validateEmail(email)){
            toast.error('Please enter a valid email address!');
            return false;
        }
        const payload = {
            email:email,
        }
        // console.log(payload);
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/send_forgot_otp');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("OTP has been sent to your email.");
                navigate('/verification-code', { state: { user_email: email } });
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
    };
  return (
    <div className="login-form-wrapper">
      <h4>Enter the email associated with your account</h4>
      <div className="login-form-container">
        <input
          className="form-input login-field"
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button disabled={loading} className="login-btn" onClick={() => do_action()}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
