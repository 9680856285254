import React, { useEffect, useState, useRef } from "react";
import "./myCars.scss";
import CarImage from "../../../assets/images/carr.jpg";
import CarImage1 from "../../../assets/images/car1.jpg";
import CarImage2 from "../../../assets/images/car2.jpg";
import { useNavigate, Link } from "react-router-dom";

import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";

const MyCars = () => {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [delid, setDelID] = useState(0);
  const [options, setOptions] = useState(false);

  const [carstorage, setCarStorage] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      get_user_cars();

      //CHECK IF CAR HAS IN STORAGE
      const retrievedItem = localStorage.getItem("CARCOMPADDCAR");
      if (retrievedItem != null) {
        const item = JSON.parse(retrievedItem);
        setCarStorage(true);
      }

      setLoading(false);
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [isLoggedIn]);

  const handleBtnClick = () => {
    navigate("/add-new-car");
  };

  const get_user_cars = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      status: 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_user_all_cars");
    if (isError) {
      setData([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_archive = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      delid: delid,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/archive_user_car");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setPopUp(false);
        setDelID(0);
        toast.success("Your car has been archived successfully!");
        get_user_cars();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_take_skip_step = async () => {
    localStorage.removeItem("CARCOMPADDCAR");
    setCarStorage(false);
  };

  const handleViewoffers = async (id) => {
    navigate(`/view-offers/${id}`);
  };

  return (
    <div className="mycars-main pt-10">
      <div className="mycars-wrapper">
        <div className="mycars-heading-container py-2">
          <h4> Mina Bilar</h4>
          {data.length > 0 && (
            <button className="new_car_add" onClick={handleBtnClick}>
              <i className="fa fa-plus-circle"></i>  LÄGG TILL BIL
            </button>
          )}
        </div>

        <div className="mycars-type">
          {data.length == 0 && !loading && (
            <>
              <div className="no_car">
                <div className="no_car_text">
                  No Car found! Add your first Car.
                </div>
                <div
                  className="new_car_button"
                  onClick={() => handleBtnClick()}
                >
                  <i className="fa fa-plus-circle"></i>
                  <dv className="">- LÄGG TILL BIL
                  </dv>
                </div>
              </div>
            </>
          )}

          {data.map((data, index) => {
            return (
              <div className="mycars-cards" key={index}>
                <div className="relative">
                  <div
                    className="icon_archive"
                    onClick={() => {
                      setPopUp(true);
                      setDelID(data.id);
                    }}
                  >
                    <i className="fa fa-trash-o"></i>
                  </div>

                  <div
                    className="edit_icon_abs"
                    onClick={() => {
                      navigate("/add-new-car?edit=" + data.id);
                    }}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                  <img src={data.image} alt="Car" />
                </div>
                <p className="mycars-name">{data.title}</p>
                <p className="mycars-intro">
                  {data.trime_val} - {data.trim}
                </p>
                <p className="mycars-intro">
                  <div className="car_into_flex">
                    <div className="">
                      {data.engine_1}, {data.engine_petrol}{" "}
                      {data.additional_data.length > 0 && <>.</>}
                    </div>
                    <div className="optios_car">
                      {data.additional_data.length > 0 && (
                        <div onClick={() => setOptions(data.additional_data)}>
                          {data.additional_data.length} Options{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </p>
                <div className="mycars-divider" />
                <p className="mycars-color">
                  {data.color == -1 ? (
                    <>
                      <div className="" style={{ color: "#ff6262" }}>
                        Any Color
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="cirlce-colorr"
                        style={{ background: data.color_code }}
                      ></div>
                      {data.color}
                    </>
                  )}
                </p>

                <div className="mycars-btn-container">
                  <button
                    disabled={data?.offers_count === 0 ? true : false}
                    onClick={() => handleViewoffers(data?.id)}
                    className={`mycars-view-specs-btn ${
                      data?.offers_count === 0
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    {data?.offers_count === 0
                      ? " Inväntar Offert..."
                      : "Se Offert"}
                  </button>
                </div>
              </div>
            );
          })}

          {popup && (
            <>
              <div className="outer_image">
                <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                      Archive this car
                    </h4>
                    <p className="small_pop_text">
                      By archiving this car it will no longer appear in this
                      list. If you would still like to view it, you can find it
                      under Archived cars.
                    </p>

                    <div className="popup_flex">
                      <button
                        className="new_car_add"
                        style={{
                          color: "white",
                          cursor: "pointer",
                          background: "#fb7878",
                        }}
                        onClick={() => setPopUp(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="new_car_add"
                        onClick={() => do_archive()}
                      >
                        Archive
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {options && (
            <>
              <div className="outer_image">
                <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                      Additional Options
                    </h4>

                    {options.map((v, i) => (
                      <>
                        <div className="box_price_pop">
                          <div className="">{v.title}</div>
                          <div className="price">{v.price}</div>
                        </div>
                      </>
                    ))}

                    <div
                      style={{
                        color: "red",
                        cursor: "pointer",
                        marginTop: "20px",
                      }}
                      onClick={() => setOptions(false)}
                    >
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {carstorage && (
            <>
              <div className="outer_image">
                <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                      Continue Adding Car
                    </h4>

                    <div className="">
                      Seems like, you were adding a car before login. Your car
                      is still in draft mode. Do you want to continue or Discard
                      to cancel the process.
                    </div>
                    <div className="popup_flex">
                      <div
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => do_take_skip_step()}
                      >
                        Discard
                      </div>
                      <button
                        className="new_car_add"
                        onClick={() => navigate("/add-new-car?proceed=1")}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCars;
