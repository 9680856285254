import React, { useEffect, useState } from "react";
import "./fraudDisclaimer.scss";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";

const FraudDisclaimerContent = () => {
  const [Data, setData] = useState({});

  const [Loading, setLoading] = useState(false);

  const GetStaticpagesInfo = async () => {
    const payload = {
      slug: "fraud-disclaimer",
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, `Auth/getStaticPage`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetStaticpagesInfo();
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: Data?.descriptions }}
      className="fraud-disclaimer-wrapper"
    ></div>
  );
};

export default FraudDisclaimerContent;
