import React, { useEffect, useState, useRef } from "react";
import "../login/login.scss";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "./../../utils/functions";
import { ToastContainer, toast } from "react-toastify";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadimg, setUploadImg] = useState("");
  const [userimage, setUserImage] = useState("");
  const [popup, setPopUp] = useState(false);
  const [google, setGoogle] = useState(0);

  const [fullname, setFullName] = useState('');
  const [isValid, setIsValid] = useState(true);

  const [data, setData] = useState([]);
  const [region, setRegion] = useState([]);
  const [regionselect, setRegionSelect] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [authorized, setAuthorized] = useState('');
  const [used, setUsed] = useState('');
  const [brand, setBrand] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');

  const [opening, setOpening] = useState('');
  const [closing, setClosing] = useState('');

  const [type, setType] = useState(0);
  useEffect(()=>{
      setType(getLoggedObject()?.user_type);
  },[])

  useEffect(()=>{
    if(getLoggedObject()?.user_type==2){
      get_car_makes();
      get_all_regions()
    }
  },[type])

  const get_all_regions = async () => {
      const payload = {
        car: 1,
      };
      setLoading(true);
      const { isError, data } = await doPost(payload, "auth/get_sweden_regions");
      if (isError) {
          setRegion([]);
          toast.error("Something went wrong with server.");
          setLoading(false);
      } else {
        if (data.action == "success") {
          setLoading(false);
          setRegion(data?.data);
        } else {
          setLoading(false);
          toast.error(data?.error);
        }
      }
    };

  const get_car_makes = async () => {
      const payload = {
        token: getLoggedObject()?.token,
      };
      setLoading(true);
      const { isError, data } = await doPost(payload, "auth/get_all_cars");
      if (isError) {
        setData([]);
        toast.error("Something went wrong with server.");
        setLoading(false);
      } else {
        if (data.action == "success") {
          setLoading(false);
          setData(data?.data);
        } else {
          setLoading(false);
          toast.error(data?.error);
        }
      }
  };

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      setLoading(false);
    } else {
      
      navigate("/login");

    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin()
      get_user_data();
    }
  }, [isLoggedIn]);

  const get_user_data = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_specific_user");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setFirstName(data?.data.name)
        setLastName(data?.data.lastname)
        setEmail(data?.data.email)
        setUserImage(data?.data.image)
        setGoogle(data?.data.google);

        setFullName(data?.data.name)
        setPhone(data?.data.phone)
        setAuthorized(data?.data.authorized)
        setUsed(data?.data.used)
        setBrand(data?.data.brand)
        setAddress(data?.data.address)
        setRegionSelect(data?.data.region)
        setWebsite(data?.data.website)
        setDescription(data?.data.about)
        setOpening(data?.data.opening)
        setClosing(data?.data.closing)


        setLoading(false);
        // toast.success("Your profile information has been updated successfully!")
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_update_user = async () => {
    if(type==1){
      var payload = {
        token: getLoggedObject()?.token,
        firstname: firstname,
        lastname: lastname,
        image: uploadimg
      };
    }
    if(type==2){
      var payload = {
        token: getLoggedObject()?.token,
        fullname: fullname,
        phone:phone,
        authorized:authorized,
        used:used,
        brand:brand,
        address:address,
        region:regionselect,
        website:website,
        about:description,
        opening:opening,
        closing:closing,
        image: uploadimg
      };
    }
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/update_user");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success("Your profile information has been updated successfully!")
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const clickImage = () => {
    document.getElementById('fileInput').click();
  };

  const onFileChange = (e) => {
    let files = e.target.files;
    if(files.length > 0){
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setUploadImg(event.target.result);
        const userImage = document.querySelector('.user_round_image');
        userImage.src = event.target.result;
      }
    } else {
      console.log("i am cancelled")
    }
}
  const delete_specific_user = async () => {
      const payload = {
        token: getLoggedObject()?.token,
      };
      setLoading(true);
      const { isError, data } = await doPost(payload, "auth/delete_specific_user");
      if (isError) {
        toast.error("Something went wrong with server.");
        setLoading(false);
      } else {
        if (data.action == "success") {
          setLoading(false);
          toast.success("Your profile has been deleted successfully!");
          
          setTimeout(() => {
            localStorage.removeItem("CARCOMAUTH");
            setLoggedObject(null);
            window.location.href = '/';
          }, 2000);
          
        } else {
          setLoading(false);
          toast.error(data?.error);
        }
      }
  };

  

  return (
    <div className="login-form-wrapper">
      <h4>Settings</h4>

    {
      type==1 &&
      <>
      <div className="login-form-container">

        <div className="mob_center" style={{marginBottom:"20px"}}>
          {
            google == 1 ?
              <>
              <img src={userimage} className="user_round_image" />
              <small style={{color:"red"}}>
                <br></br>
                This account is linked with Google!
              </small>
              </>
            :
            <>
              <img src={userimage} className="user_round_image" onClick={(e)=>clickImage(e)}  style={{cursor:"pointer"}}/>
              <input type="file" id="fileInput" className="form-control" name="image" onChange={(e)=>onFileChange(e)} style={{display:"none"}} />
            </>
          }
            
        </div>
        <input
          className="form-input login-field"
          placeholder="First Name"
          type="text"
          value={firstname}
          onChange={(e) => setFirstName(e.target.value)}
        />
       
          <input
            className="form-input login-field"
            placeholder="Last Name"
            type="text"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
          />

       
        <input
          className="form-input login-field"
          placeholder="Email Address"
          type="email"
          value={email}
          disabled
        />

       

        <button className="login-btn" onClick={()=>do_update_user()}>
          Update
        </button>
      </div>
      </>
    }

    {
      type==2 &&
      <>
      <div className="login-form-container w50per">

        <div className="mob_center" style={{marginBottom:"20px"}}>
            {
              google == 1 ?
                <>
                <img src={userimage} className="user_round_image" />
                <small style={{color:"red"}}>
                  <br></br>
                  This account is linked with Google!
                </small>
                </>
              :
              <>
                <img src={userimage} className="user_round_image" onClick={(e)=>clickImage(e)}  style={{cursor:"pointer"}}/>
                <input type="file" id="fileInput" className="form-control" name="image" onChange={(e)=>onFileChange(e)} style={{display:"none"}} />
              </>
            }
              
          </div>
        <div className="custom_wrap_login">
            <div className="form_inp_signup">
                <label>Email Address</label>
                <input
                    className="form-input login-field"
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    required
                    disabled
                    onChange={(e) => setEmail(e.target.value)}
                    style={{background:"#f5f5f5"}}
                />
            </div>
            <div className="form_inp_signup">
                <label>Company name</label>
                <input
                    className="form-input login-field"
                    placeholder="Company name"
                    type="text"
                    value={fullname}
                    required
                    onChange={(e) => setFullName(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label>Phone Number</label>
                <input
                    className="form-input login-field"
                    placeholder="Phone Number"
                    type="number"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label> Are you authorized dealer?</label>
                <select name="authorized" required className="form-input login-field" value={authorized} onChange={(e)=>setAuthorized(e.target.value)}>
                    <option value="">-- Authorized Dealer--</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                </select>
            </div>
            <div className="form_inp_signup">
                <label> Sell new or used car?</label>
                <select name="sell" required className="form-input login-field" value={used} onChange={(e)=>setUsed(e.target.value)}>
                    <option value="">--New/Used cars--</option>
                    <option value="1">New</option>
                    <option value="2">Used</option>
                </select>
            </div>
            <div className="form_inp_signup">
                <label>Specialized in any car brand?</label>
                <select name="spealized" required className="form-input login-field" value={brand} onChange={(e)=>setBrand(e.target.value)}>
                    <option value="">--Choose Brand--</option>
                    {data.map((v, i)=>(
                        <>
                            <option value={v.id}>{v.title}</option>
                        </>
                    ))}
                </select>
            </div>
            
            <div className="form_inp_signup">
                <label>Address</label>
                <input
                    className="form-input login-field"
                    placeholder="Address"
                    type="text"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
            </div>

            <div className="form_inp_signup">
                <label>Region</label>
                <select name="spealized" required className="form-input login-field" value={regionselect} onChange={(e)=>setRegionSelect(e.target.value)}>
                    <option value="">--Choose Region--</option>
                    {region.map((v, i)=>(
                        <>
                            <option value={v.id}>{v.title}</option>
                        </>
                    ))}
                </select>
            </div>

            <div className="form_inp_signup wd100">
                <label>Website</label>
                <input
                    className="form-input login-field"
                    placeholder="Website URL"
                    type="url"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label>Opening Time</label>
                <input
                    className="form-input login-field"
                    placeholder=""
                    type="time"
                    value={opening}
                    onChange={(e) => setOpening(e.target.value)}
                />
            </div>
            <div className="form_inp_signup">
                <label>Closing Time</label>
                <input
                    className="form-input login-field"
                    placeholder=""
                    type="time"
                    value={closing}
                    onChange={(e) => setClosing(e.target.value)}
                />
            </div>
            <div className="form_inp_signup wd100">
                <label>About info</label>
                <textarea
                    className="form-input login-field"
                    placeholder="Enter your business details here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      height:"120px"
                    }}
                ></textarea>
            </div>

            

            <button className="login-btn" disabled={loading} onClick={()=>do_update_user()}>Update</button>
        </div>
      </div>
      </>
    }

      <div className="login-form-container w50per">
        <h3>Delete your account</h3>
        <p className="delete-text">
          Deleting your account is permanent, and will remove your ability to
          log in and receive offers.
        </p>

        <button className="delete-btn" onClick={()=> setPopUp(true)}>
          Delete Account
        </button>
      </div>
     


        {
            popup &&
          <>
            <div className="outer_image">
              <div className="inner_image">
                  <div className="box_inner_image">
                    <h4 style={{fontSize:"18px", marginBottom:"20px"}}>DELETE ACCOUNT?</h4>
                    <p>Are you sure you want to delete this account?</p>

                    <button className="login-btn" onClick={()=> delete_specific_user()}>
                        Confirm
                    </button>
                    <div style={{color:"red", cursor:"pointer"}} onClick={()=> setPopUp(false)}>
                        Cancel
                    </div>
                  </div>
              </div>
            </div>
          </>
        }

        
    </div>
  );
};

export default ProfileSettings;
