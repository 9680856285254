import React, { useState } from "react";
import "./addNewCar.scss";

const ChoosePaymentMethod = ({ carComponents, setCarComponents }) => {
  const paymentOptions = [
    { label: " kontantbetalning", value: "cash" },
    { label: "Finansiering med kredit", value: "pdpfinancing" },
    { label: "Leasing", value: "leasing" },
  ];

  const [selectedCars, setSelectedCars] = useState([]);

  const handleCarOptionClick = (carName) => {
    const isSelected = selectedCars.includes(carName);
    if (isSelected) {
      setSelectedCars(
        selectedCars.filter((selectedCar) => selectedCar !== carName)
      );
    } else {
      setSelectedCars([...selectedCars, carName]);
    }
  };

  return (
    <div className="addNewCar-main">
      <div className="addNewCar-wrapper">
        <div className="addNewCar-heading-container">
          <h4>Vilken betalningsmetod föredrar du?
          </h4>
          <div className="btn-container">
            <button
              className="addNewCar-build-new-car-btn"
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  choosePaymentMethod: false,
                  chooseType: true,
                })
              }
            >
              Tillbaka
            </button>
            <button
              className={`addNewCar-build-new-car-btn ${
                selectedCars.length > 0 ? "" : "disabled"
              }`}
              disabled={selectedCars.length === 0}
              onClick={() =>
                setCarComponents({
                  ...carComponents,
                  choosePaymentMethod: false,
                  chooseFuel: true,
                })
              }
            >
              Nästa
            </button>
          </div>
        </div>

        <div className="type-options-container">
          <p>Flera val kan göras</p>
          {paymentOptions.map((option, index) => (
            <div
              key={index}
              className={`type-option ${
                selectedCars.includes(option.value) ? "selected" : ""
              }`}
              onClick={() => handleCarOptionClick(option.value)}
            >
              <input
                type="checkbox"
                name="carOption"
                value={option.value}
                checked={selectedCars.includes(option.value)}
                onChange={() => handleCarOptionClick(option.value)}
              />
              <p>{option.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChoosePaymentMethod;
