import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import FraudDisclaimerContent from "../components/fraudDisclaimer/fraudDisclaimer";

const FraudDisclaimer = () => {
  return (
    <>
      <SubHeader subTitle="Ansvarsfriskrivning Från Bedrägeri" />
      <FraudDisclaimerContent />
    </>
  );
};

export default FraudDisclaimer;
