import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import PrivacyPolicyContent from "../components/privacyPolicy/privacyPolicy";

const PrivacyPolicy = () => {
  return (
    <>
      <SubHeader subTitle="GDPR" />
      <PrivacyPolicyContent />
    </>
  );
};

export default PrivacyPolicy;
