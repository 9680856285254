import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import DealerLoginForm from "../components/login/dealerLogin/dealerLogin";

const DealerLogin = () => {
  return (
    <>
      <SubHeader subTitle="Dealer Login" />
      <DealerLoginForm />
    </>
  );
};

export default DealerLogin;
