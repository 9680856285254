import React, { useEffect, useState } from "react";
import { IoIosAttach } from "react-icons/io";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { doPost } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import axios from "axios";
import { urls } from "../../utils/Api_urls";
import { FcDocument } from "react-icons/fc";

const Chat = () => {
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [CurrentUser, setCurrentUser] = useState(null);
  const [currentUserchat, setCurrentUserchat] = useState([]);
  const { user = {} } = useSelector((state) => state.user);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...selectedFiles]);
  };

  const handleAttachmentClick = () => {
    document.getElementById("fileInput").click();
  };

  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const GetChats = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, `Auth/getConversations`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setUsers(data?.data);
      } else {
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetChats();
  }, []);

  const [Messagesend, setMessageSend] = useState(false);

  const sendMessage = async () => {
    if (CurrentUser == null) {
      return toast.error("Please select user");
    }
    if (message === "" && files.length === 0) {
      return toast.error("Please enter a message or upload a file.");
    }

    const token = getLoggedObject()?.token;

    const formData = new FormData();
    formData.append("token", token);
    formData.append("convo_id", CurrentUser?.convo_id);
    formData.append("message", message);
    files.forEach((file, index) => {
      formData.append("files[]", file);
    });

    setMessageSend(true);
    const { isError, data } = await axios.post(
      `${urls?.API}/Auth/sendMessage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setMessageSend(false);
    } else {
      if (data?.status) {
        setMessageSend(false);
        setMessage("");
        Getmessages();
        setFiles([]);
      } else {
        setMessageSend(false);
        toast.error(data?.message);
      }
    }
  };

  const Getmessages = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      convo_id: CurrentUser?.convo_id,
    };
    const { isError, data } = await doPost(payload, `Auth/getMessages`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        GetChats();
        setCurrentUserchat(data?.data);
      } else {
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    Getmessages();
  }, [CurrentUser]);

  useEffect(() => {
    Getmessages();
    const intervalId = setInterval(() => {
      Getmessages();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [CurrentUser]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="lg:py-10 py-2">
      {/* <!-- component --> */}
      <div className="flex flex-col lg:flex-row lg:h-[85vh] overflow-hidden w-[95%] m-auto border">
        {/* <!-- Sidebar --> */}
        <div className="xl:w-1/4 lg:w-1/3 bg-white border-r border-gray-300">
          {/* <!-- Sidebar Header --> */}
          <header className="p-4 border-b border-gray-300 flex justify-between items-center bg-green-800 text-white">
            <h1 className="text-2xl font-semibold">Chattar</h1>
          </header>

          {/* <!-- Contact List --> */}
          <div className="overflow-y-auto flex-1 p-3">
            {users.length > 0
              ? users.map((user) => {
                  return (
                    <div
                      key={user?.id}
                      onClick={() => setCurrentUser(user)}
                      className="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded-md border-b"
                    >
                      <div className="w-12 h-12 bg-gray-300 rounded-full mr-3">
                        <img
                          src={`${
                            user?.image
                              ? user?.image
                              : "https://placehold.co/200x/ffa8e4/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato"
                          }`}
                          alt="Avatar"
                          className="w-12 h-12 rounded-full"
                        />
                      </div>
                      <div className="flex-1">
                        <h2 className="text-lg font-semibold">{user?.name}</h2>
                        <p className="text-gray-600">{user?.last_message}</p>
                      </div>
                      <div className="flex flex-col items-center">
                        {user.unread_count != 0 && (
                          <div className="h-[20px] w-[20px] rounded-full bg-green-500 text-white flex items-center justify-center font-semibold text-xs">
                            {user?.unread_count}
                          </div>
                        )}
                        <p className="text-[8px]">
                          {formatTime(user?.last_message_time)}
                        </p>
                      </div>
                    </div>
                  );
                })
              : "No Conservation"}
          </div>
        </div>

        {/* <!-- Main Chat Area --> */}
        <div className="lg:flex-1 relative">
          {/* <!-- Chat Header --> */}
          <header className="bg-gray-100 p-4 text-gray-700">
            <h1 className="text-2xl font-semibold">
              {CurrentUser?.name || "Välj Chat "}
            </h1>
          </header>

          {/* <!-- Chat Messages --> */}
          <div className="flex-1 h-[80vh] overflow-y-auto p-4 pb-36">
            {Loading && "Loading..."}
            {CurrentUser !== null && currentUserchat
              ? currentUserchat.map((chat, index) => {
                  return (
                    <div key={index}>
                      {user?.id === chat?.sender_id ? (
                        <div className="flex justify-end mb-4 cursor-pointer">
                          <div className="flex flex-col items-end max-w-96 bg-green-800 text-white rounded-lg p-3">
                            <p>{chat?.message}</p>

                            {chat?.files && chat?.files !== null && (
                              <div>
                                {["jpg", "jpeg", "png", "gif"]?.includes(
                                  chat.files?.file_type
                                ) ? (
                                  <img
                                    className="max-h-40 w-auto object-cover rounded-lg mt-2"
                                    src={chat.files.file_name}
                                    alt="chat image"
                                  />
                                ) : (
                                  <a
                                    href={chat.files.file_name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:text-blue-600"
                                  >
                                    <FcDocument className="text-4xl" />
                                  </a>
                                )}
                              </div>
                            )}
                            <p className="text-[8px] mt-4">
                              {formatTime(chat?.created_at)}
                            </p>
                          </div>
                        </div>
                      ) : (
                        // Incoming Messages
                        <div className="flex mb-4 cursor-pointer">
                          <div className="flex flex-col max-w-96 bg-green-400 rounded-lg p-3">
                            <p className="text-gray-900">{chat?.message}</p>

                            {chat?.files && chat?.files !== null && (
                              <div>
                                {["jpg", "jpeg", "png", "gif"].includes(
                                  chat.files?.file_type
                                ) ? (
                                  <img
                                    className="max-h-40 w-auto object-cover rounded-lg mt-2"
                                    src={chat.files.file_name}
                                    alt="chat image"
                                  />
                                ) : (
                                  <a
                                    href={chat.files.file_name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-2xl"
                                  >
                                    <FcDocument className="text-4xl" />
                                  </a>
                                )}
                              </div>
                            )}
                            <p className="text-[8px] mt-4">
                              {formatTime(chat?.created_at)}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              : "No Messages"}

            <div className="flex mt-2 absolute bottom-[80px] z-20">
              {files.map((file, index) => (
                <div key={index} className="mr-2">
                  {file.type.startsWith("image/") ? (
                    <img
                      className="h-10 w-10 object-cover rounded-lg"
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index}`}
                    />
                  ) : (
                    <p className="text-sm">{file.name}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          {CurrentUser !== null && (
            <footer className="bg-white border-t border-gray-300 p-4 absolute bottom-0 w-full z-20">
              <div className="flex items-center">
                <div className="relative flex-1">
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*, .pdf, .doc, .docx"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <input
                    type="text"
                    placeholder="Type a message..."
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ color: "black" }}
                    value={message}
                    className="w-full p-2 rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
                  />
                  <IoIosAttach
                    className="absolute text-xl right-2 top-[10px] cursor-pointer"
                    onClick={handleAttachmentClick}
                  />
                </div>
                <button
                  onClick={sendMessage}
                  className="bg-green-700 text-white px-4 py-2 rounded-md ml-2 lg:w-auto"
                >
                  {Messagesend ? "Sending..." : "Send"}
                </button>
              </div>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
