import "./footer.scss";
import { Link } from "react-router-dom";
import WhiteLogo from "../../assets/images/white-logo.jpeg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { doPost } from "../../utils/apiCalls";

export const Footer = () => {
  const [Data, setData] = useState({});

  const [Loading, setLoading] = useState(false);

  const GetStaticpagesInfo = async () => {
    const payload = {};
    setLoading(true);
    const { isError, data } = await doPost(payload, `Auth/getSettings`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setData(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetStaticpagesInfo();
  }, []);

  return (
    <footer className="footer-wrapper flex column-direction w-100">
      <div className="footer-widget flex column-direction w-100">
        <div className="container flex">
          <div className="footer-info">
            <Link className="logo mr-auto" to="/">
              <img src={WhiteLogo} alt="logo" />
            </Link>
            <div className="contact-info-footer">
              <i
                class="fa fa-phone"
                style={{ color: "white", fontSize: "20px" }}
                aria-hidden="true"
              ></i>
              <p className="contact-into-content">{Data?.mobile}</p>
            </div>
            <div className="contact-info-footer">
              <i
                class="fa fa-envelope"
                style={{ color: "white", fontSize: "20px" }}
                aria-hidden="true"
              ></i>
              <p>{Data?.email}</p>
            </div>
            <div className="contact-info-footer">
              <i
                class="fa fa-map-marker"
                style={{ color: "white", fontSize: "20px" }}
                aria-hidden="true"
              ></i>
              <p>{Data?.address}</p>
            </div>
            <ul className="social-icons list-style-none flex">
              <li>
                <a
                  href={Data?.facebook}
                  target="_blank"
                  className="fa fa-facebook"
                ></a>
              </li>
              <li>
                <a
                  href={Data?.twitter}
                  target="_blank"
                  className="fa fa-twitter"
                ></a>
              </li>
              <li>
                <a
                  href={Data?.instagram}
                  target="_blank"
                  className="fa fa-instagram"
                ></a>
              </li>
              <li>
                <a
                  href={Data?.youtube}
                  target="_blank"
                  className="fa fa-youtube"
                ></a>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <section>
              <h4>Sidor</h4>
              <ul className="list-style-none">
                <li>
                  <Link to="/">Hem</Link>
                </li>
                <li>
                  <Link to="/about-us">Om Oss</Link>
                </li>
                <li>
                  <Link to="/">Köp Bil</Link>
                </li>
                <li>
                  <Link to="/contact-us"> Kontakta Oss</Link>
                </li>
              </ul>
            </section>
          </div>
          <div className="footer-links-2">
            <section>
              <h4>Genvägar</h4>
              <ul className="list-style-none">
                <>
                  <li>
                    <Link to="/terms-conditions">Villkor</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">GDPR</Link>
                  </li>
                  <li>
                    <Link to="/fraud-disclaimer"> Ansvarsfriskrivning Från Bedreägeri</Link>
                  </li>
                  <li>
                    <Link to="/frequently-asked-questions"> Frågor Och Svar</Link>
                  </li>
                </>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="copyright flex column-direction items-center content-justify-center">
        <p>© 2023 Car Compare. All rights reserved.</p>
      </div>
    </footer>
  );
};
