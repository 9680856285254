import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const GoogleMap = (props) => {
  return (
    <Map
      google={props.google}
      zoom={14}
      initialCenter={{ lat: 37.7749, lng: -122.4194 }}
      style={{
        width: "30%",
        height: "440px",
        borderRadius: "10px",
        position: "relative",
      }}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: "YOUR_GOOGLE_MAPS_API_KEY_HERE",
})(GoogleMap);
