import React, { useEffect, useState, useRef } from "react";
import "../buyerSignup.scss";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

const BuyerSignupForm = () => {

  const {getLoggedObject, setLoggedObject, isLoggedIn, checkLogin} = useAuth();
    const navigate = useNavigate();
    const [fullname, setFullName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [isValid, setIsValid] = useState(true);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const isValidPassword = newPassword.length >= 6 && /[A-Z]/.test(newPassword);
        setIsValid(isValidPassword);
    };

    const handleLogin = async () => {
        setLoading(true);
        const logged = await checkLogin();
        if(logged){
            navigate('/dashboard');
        }else{
            setLoading(false);
        }
      }

      useEffect(()=>{
            if(isLoggedIn){
                handleLogin();
            }
        },[isLoggedIn])

    const do_signup = async () => {
        if(fullname===""){
            toast.error('Please enter first name!');
            return;
        }
        if(lastname===""){
          toast.error('Please enter last name!');
          return;
      }
        if(email===""){
            toast.error('Please enter your email address!');
            return;
        }
        if(!validateEmail(email)){
            toast.error('Please enter a valid email address!');
            return false;
        }
        if(password===""){
            toast.error('Please enter your password!');
            return;
        }
        if(cpassword===""){
          toast.error('Please enter confirm password!');
          return;
        }
        if(cpassword!== password){
          toast.error('Password & confirm password should be matched!');
          return;
        }
        const payload = {
            fullname:fullname,
            lastname: lastname,
            email:email,
            password:password,
        }
        // console.log(payload);
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/user_signup');
        // console.log(data);
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("Your account has been created & Verification email has been sent to your email address!");
                navigate("/login");
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
    };
  return (
    <div className="login-form-wrapper">
      {/* <h4>Sign Up</h4> */}
      <div className="login-form-container">
        <input
          className="form-input login-field"
          placeholder="First Name"
          type="name"
          value={fullname}
          onChange={(e) => setFullName(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Last Name"
          type="name"
          value={lastname}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Confirm Password"
          type="password"
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <button className="login-btn" disabled={loading} onClick={()=>do_signup()}>Sign Up</button>
        <div className="btn-bottom-content">
          <p className="have-account">
            Already have an account? <a href="/login">Login</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BuyerSignupForm;
