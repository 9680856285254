import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../forgotPassword.scss";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "./../../../hooks/useAuth";

const UpdatePasswordForm = () => {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [cpassword, setCPassword] = useState('');
  const [password, setPassword] = useState('');
  const [oldpass, setOldPass] = useState('');
  const [loading, setLoading] = useState(false);


  const do_update_password = async () => {
    if(password==""){
      toast.error('Please enter old password!');
      return;
  }
    if(password==""){
        toast.error('Please enter your password!');
        return;
    }
    if(cpassword==""){
        toast.error('Please enter confirm password!');
        return;
    }
    if(cpassword!==password){
        toast.error('Password & confirm password not matching!');
        return;
    }
    const payload = {
        token: getLoggedObject()?.token,
        newpass:password,
        cnewpass:cpassword,
        current: oldpass
    }
    setLoading(true);
    const {isError, data} = await doPost(payload, 'auth/update_password');
    if(isError) {
        toast.error('Something went wrong with server.');
        setLoading(false);
    }else{
        if (data.action == "success") {
            setLoading(false);
            setCPassword("")
            setOldPass("")
            setPassword("")
            toast.success("Your password has been updated successfully!");
        }
        else {
            setLoading(false);
            toast.error(data?.error);
        }
    }
    
}


  return (
    <div className="login-form-wrapper">
      <div className="login-form-container">
      <input
          className="form-input login-field"
          placeholder="Old Password"
          type="password"
          value={oldpass}
          onChange={(e) => setOldPass(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Confirm New Password"
          type="password"
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <button className="login-btn" onClick={()=>do_update_password()}>
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdatePasswordForm;
