import "./subHeader.scss";

const SubHeader = (props) => {
  return (
    <div className={`sub-header ${props.extraClass}`}>
      <span>{props.headerTitle}</span>
      <h1>{props.subTitle}</h1>
    </div>
  );
};

export default SubHeader;
