import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../forgotPassword.scss";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";


const VerificationCodeForm = () => {
    const navigate = useNavigate();
    const params = useLocation();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showpassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const do_action = async () => {
        if(email==""){
            toast.error('Please enter your OTP COde!');
            return;
        }
        const payload = {
            otp:email,
            user_email: params.state.user_email
        }
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/verify_otp_code');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("OTP verified");
                setShowPassword(true);
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
    };

    const do_update_password = async () => {
        if(password==""){
            toast.error('Please enter your password!');
            return;
        }
        if(cpassword==""){
            toast.error('Please enter confirm password!');
            return;
        }
        if(cpassword!==password){
            toast.error('Password & confirm password not matching!');
            return;
        }
        const payload = {
            otp:email,
            user_email: params.state.user_email,
            password:password,
            cpassword:cpassword
        }
        setLoading(true);
        const {isError, data} = await doPost(payload, 'auth/update_forgot_password');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
                setLoading(false);
                toast.success("Your password has been updated successfully!");
                setShowPassword(false);
                navigate("/login");
            }
            else {
                setLoading(false);
                toast.error(data?.data);
            }
        }
        
    }

  return (
    <>
    
    {
      showpassword &&  
    <div className="login-form-wrapper">
      <h4>Enter your new Password</h4>
      <div className="login-form-container">
        <input
          className="form-input login-field"
          placeholder="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="form-input login-field"
          placeholder="Confirm New Password"
          type="password"
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <button disabled={loading} className="login-btn" onClick={()=> do_update_password()}>
          Update
        </button>
      </div>
    </div>
  }
    

    {
      !showpassword &&  
      <div className="login-form-wrapper">
        <h4>Enter the 6 digit verification code</h4>
        <div className="login-form-container">
          <input
            className="form-input login-field"
            placeholder="6 Digit Verification Code"
            type="number"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button disabled={loading} className="login-btn" onClick={()=> do_action()}>
            Submit
          </button>
        </div>
      </div>
    }

    </>
    
  );
};

export default VerificationCodeForm;
