import { useEffect, useState } from "react";
import "./header.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

import ColorLogo from "../../assets/images/color-logo.jpeg";
import profileImage from "../../assets/images/profile-icon.png";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { ToastContainer, toast } from "react-toastify";

export const LoginHeader = () => {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [scroll, setScroll] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [proimage, setproimage] = useState(profileImage);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = () => {
    toggleDropdown();
  };

  const do_logout = async () => {
    localStorage.removeItem("CARCOMAUTH");
    setLoggedObject(null);
    window.location.href = "/";
  };

  useEffect(() => {
    if (isLoggedIn) {
      get_user_data();
    }
  }, [isLoggedIn]);

  const get_user_data = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/get_specific_user");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        setTotal(data?.total_notif);
        setproimage(data?.data.image);
      } else {
        toast.error(data?.error);
      }
    }
  };

  const [type, setType] = useState(0);
  useEffect(() => {
    setType(getLoggedObject()?.user_type);
  }, []);

  return (
    <header className={`header-wrapper is_sticky`}>
      <div className="container fluid flex items-center">
        <Link className="logo mr-auto" to="/">
          <img src={ColorLogo} alt="logo" />
        </Link>
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsMenuOpen(false);
          }}
        >
          <button className={`hamburger fa fa-bars`} onClick={toggleMenu} />

          <div className={`navigation-wrap flex ${isMenuOpen ? "show" : ""}`}>
            <ul className="list-style-none flex items-center navigation">
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to="/dashboard">
                Mina Sidor
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to="/chat">
                  {/* Messages */}
                  Meddelanden
                </NavLink>
              </li>
              {getLoggedObject().user_type == "2" && (
                <li>
                  <NavLink onClick={() => setIsMenuOpen(false)} to="/reports">
                  statistik
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  onClick={() => setIsMenuOpen(false)}
                  to="/notifications"
                >
                  {/* Notifications */}
                  Notiser
                </NavLink>
                {total > 0 && <span className="total_count">{total}</span>}
              </li>

              <div className="custom-dropdown">
                <div className="selected-language" onClick={toggleDropdown}>
                  <img
                    src={proimage}
                    alt="profile"
                    className="pro_image_header_login"
                  />
                </div>
                {isOpen && (
                  <div className="dropdown-options">
                    <div
                      className="option"
                      onClick={() => handleLanguageSelect()}
                    >
                      <span
                        onClick={() => {
                          setIsMenuOpen(false);
                          navigate("/profile-settings");
                        }}
                      >
                        {/* Profile Settings */}
                        Min Profil
                      </span>
                      <span
                        onClick={() => {
                          setIsMenuOpen(false);
                          navigate("/update-password");
                        }}
                      >
                        {/* Update Password */}
                        Uppdatera Lösenord
                      </span>
                      {type == 1 && (
                        <span
                          onClick={() => {
                            setIsMenuOpen(false);
                            navigate("/archived-cars");
                          }}
                        >
                          {/* Archived Cars */}
                          Mottagna Bilar 
                        </span>
                      )}
                      <span
                        onClick={() => {
                          setIsMenuOpen(false);
                          navigate("/contact-us");
                        }}
                      >
                        {/* Contact Us */}
                        Kontakta Oss

                      </span>
                      <span onClick={() => do_logout()}>
                        {/* Log Out */}
                        Logga Ut
                        </span>
                    </div>
                  </div>
                )}
              </div>
            </ul>
          </div>
        </OutsideClickHandler>
      </div>
    </header>
  );
};
