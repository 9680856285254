import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import FAQContent from "../components/faq/faqContent";

const FAQ = () => {
  return (
    <>
      <SubHeader subTitle="Frågor Och Svar" />
      <FAQContent />
    </>
  );
};

export default FAQ;
