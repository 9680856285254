import React from "react";
import ChooseMake from "../components/addNewCar/chooseMake";
import { useState } from "react";
import ChooseModel from "../components/addNewCar/chooseModel";
import ChooseType from "../components/addNewCar/chooseType";
import ChoosePaymentMethod from "../components/addNewCar/choosePaymentMethod";
import ChooseFuel from "../components/addNewCar/chooseFuel";

const AddNewCar = () => {
  const [carComponents, setCarComponents] = useState({
    chooseMake: true,
    chooseModel: false,
    chooseType: false,
    choosePaymentMethod: false,
    chooseFuel: false,
  });
  return (
    <div className="pt-5">
      {carComponents.chooseMake ? (
        <ChooseMake
          carComponents={carComponents}
          setCarComponents={setCarComponents}
        />
      ) : carComponents.chooseModel ? (
        <ChooseModel
          carComponents={carComponents}
          setCarComponents={setCarComponents}
        />
      ) : carComponents.chooseType ? (
        <ChooseType
          carComponents={carComponents}
          setCarComponents={setCarComponents}
        />
      ) : carComponents.choosePaymentMethod ? (
        <ChoosePaymentMethod
          carComponents={carComponents}
          setCarComponents={setCarComponents}
        />
      ) : (
        <ChooseFuel
          carComponents={carComponents}
          setCarComponents={setCarComponents}
        />
      )}
    </div>
  );
};

export default AddNewCar;
