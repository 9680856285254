import React, { useEffect, useState } from "react";
import profile from "../../../assets/images/Profile.jpg";
import { RiStarSFill } from "react-icons/ri";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { doPost } from "../../../utils/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { setDealerData } from "../../../lib/Redux/slices/webData";

const CarConditions = [
  {
    name: "Info",
    path: "/offers-details/info",
  },
  {
    name: "Reviews",
    path: "/offers-details/reviews",
  },
];

const OfferDetailLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const isPathActive = (path) => {
    return location.pathname.includes(path);
  };
  const { dealerId, dealerData } = useSelector((state) => state.web);
  const { delar_info = {} } = dealerData;
  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const getDealrerDetails = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      `auth/getDelarReviews/${id}`
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        dispatch(setDealerData(data));
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  const roundedAverage = Math.round(delar_info?.rating);
  const starCount = 5;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      if (i < roundedAverage) {
        stars.push(
          <RiStarSFill key={i} className="text-yellow-400 text-3xl" />
        );
      } else {
        stars.push(<RiStarSFill key={i} className="text-black text-3xl" />);
      }
    }
    return stars;
  };

  useEffect(() => {
    if (id !== undefined) {
      getDealrerDetails();
    }
  }, []);

  return (
    <>
      <div className="w-[95%] m-auto pt-5">
        <div className="pb-4 w-full border-b">
          <div className="flex items-center space-x-2 sm:gap-0 gap-y-5">
            <div className="h-[120px] w-[120px] border rounded-full overflow-hidden">
              <img
                className="h-full w-full"
                src={delar_info?.image}
                alt="Profile.jpg"
              />
            </div>
            <div className="space-y-1">
              <h2 className="sm:text-xl text-base font-bold">
                {delar_info?.name}
              </h2>
              <div className="flex items-center space-x-1">
                {renderStars()} &nbsp;
                <p className="sm:text-lg underline cursor-pointer">
                  {delar_info?.reviews} reviews
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-5 border-b">
        {CarConditions &&
          CarConditions.map((type, index) => {
            return (
              <div
                key={index}
                className={`flex ${
                  index === 0
                    ? "sm:items-end items-center"
                    : "sm:items-start items-center"
                } flex-col`}
              >
                <Link
                  className="sm:w-1/4 w-[90%]"
                  to={`${type.path}/${dealerId}`}
                >
                  <div
                    className={`py-4 w-full text-lg border-b-2 flex items-center justify-center ${
                      isPathActive(type.path)
                        ? "text-black border-black"
                        : "text-[#0000007e] border-transparent"
                    }  `}
                  >
                    {type.name}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
      <div className="w-full py-5 border-b">
        <div className="w-[95%] m-auto">{children}</div>
      </div>
    </>
  );
};

export default OfferDetailLayout;
