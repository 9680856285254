import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import UpdatePasswordForm from "../components/forgotPassword/updatePasswordForm/updatePasswordForm";

const UpdatePassword = () => {
  return (
    <>
      <SubHeader subTitle="Update Password" />
      <UpdatePasswordForm />
    </>
  );
};

export default UpdatePassword;
