import React, { useState } from "react";
import { Rating } from "@material-tailwind/react";
import useAuth from "../../hooks/useAuth";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCarOffers } from "../../lib/Redux/slices/webData";

const RatingComponent = ({ value, onChange }) => {
  return (
    <div className="w-full flex">
      <Rating
        className="h-4 w-4 text-yellow-400"
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    </div>
  );
};

const Reviews = ({ pop = () => {}, id = "" }) => {
  const { userCarID } = useSelector((state) => state.web);
  const dispatch = useDispatch();
  const [rating, setRating] = useState(4);
  const [review, setReview] = useState("");

  const handleRatingChange = (newValue) => {
    setRating(newValue);
  };

  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const PostReview = async () => {
    if (rating === "") {
      return toast.error("please add rating");
    }
    if (review === "") {
      return toast.error("please enter review");
    }

    const payload = {
      token: getLoggedObject()?.token,
      offer_id: id,
      rating: rating,
      reviews: review,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/sendReview");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      handlecancel();
    } else {
      if (data?.status) {
        toast.success(data?.message);
        setLoading(false);
        getData();
        handlecancel();
      } else {
        handlecancel();
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  const getData = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: userCarID,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "Auth/getOfferDetails");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.message == "success") {
        dispatch(setCarOffers(data?.car_offers));
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handlecancel = () => {
    pop(false);
    setReview("");
    setRating(4);
  };

  return (
    <>
      <div className="sm:w-1/2 w-[96%] bg-white rounded-lg">
        <div className="space-y-2 p-5">
          <label className="text-lg font-medium" htmlFor="ReviewDesp">
            Lägg till Recension{" "}
          </label>
          <div>
            <RatingComponent value={rating} onChange={handleRatingChange} />
          </div>
        </div>
        <div className="space-y-2 p-5">
          <label className="text-lg font-medium" htmlFor="ReviewDesp">
            Enter description
          </label>
          <textarea
            id="ReviewDesp"
            placeholder="Enter review"
            className="w-full resize-none h-28 border text-sm p-2"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          ></textarea>
        </div>
        <div className="w-full border-t pt-5 flex justify-end">
          <button
            onClick={handlecancel}
            type="button"
            className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={PostReview}
            className="text-white bg-gradient-to-r mr-5 from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            {Loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Reviews;
