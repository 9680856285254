import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import ProfileSettings from "../components/profile/profileSettingsForm";

const Profile = () => {
  return (
    <>
      <SubHeader subTitle="Profile Settings" />
      <ProfileSettings />
    </>
  );
};

export default Profile;
