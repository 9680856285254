import React, { useEffect, useState, useRef } from "react";
import "./addNewCar.scss";
import { useNavigate, Link } from "react-router-dom";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "./../../utils/functions";
import { ToastContainer, toast } from "react-toastify";
import { tab } from "@testing-library/user-event/dist/tab";
import Loader from "../loader/Loader";

const ChooseMake = ({ carComponents, setCarComponents }) => {
  const carNames = [
    "Toyota",
    "Honda",
    "Ford",
    "Chevrolet",
    "BMW",
    "Mercedes",
    "Audi",
  ];

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [data, setData] = useState([]);
  const [popular, setDataPopular] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tabs, setTabs] = useState(1);
  const [datamodel, setDataModel] = useState([]);
  const [datatrim, setDataTrim] = useState([]);
  const [dataengine, setDataEngine] = useState([]);
  const [datacolor, setDataColor] = useState([]);
  const [dataadditional, setDataAdditional] = useState([]);

  const get_car_makes = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_all_cars");
    if (isError) {
      setData([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
        setDataPopular(data?.popular);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    get_car_makes();
  }, []);

  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedmodel, setSelectedModel] = useState("");

  const handleBtnClick = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (tabs == 2) {
      get_car_models();
    }
  }, [tabs]);

  const get_car_models = async () => {
    const payload = {
      // token: getLoggedObject()?.token,
      car: selectedCar,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_car_models");
    if (isError) {
      setData([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setDataModel(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const carOptions = [
    { label: "Ny", value: "new" },
    { label: "Ny och Begagnad", value: "newAndUsed" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  // 4 TAB

  const paymentOptions = [
    { label: " kontantbetalning", value: "cash" },
    { label: "Finansiering med kredit", value: "pdpfinancing" },
    { label: "Leasing", value: "leasing" },
  ];

  const [selectedCars, setSelectedCars] = useState([]);

  const handleCarOptionClick = (carName) => {
    const isSelected = selectedCars.includes(carName);
    if (isSelected) {
      setSelectedCars(
        selectedCars.filter((selectedCar) => selectedCar !== carName)
      );
    } else {
      setSelectedCars([...selectedCars, carName]);
    }
  };

  const [fuelcars, setFuelCars] = useState("");

  const handleFuelCar = (carName) => {
    // const isSelected = fuelcars.includes(carName);
    if (fuelcars == carName) {
      setFuelCars("");
      // setFuelCars(
      //   fuelcars.filter((selectedCar) => selectedCar !== carName)
      // );
    } else {
      // setFuelCars();
      setFuelCars(carName);
    }
    console.log(fuelcars);
  };

  const FuelOptions = [
    { label: "Bensin", value: "petrol" },
    { label: "Diesel", value: "diesel" },
    { label: "Hybrid", value: "hybrid" },
  ];

  // 6 TAV
  const gearoptions = [
    { label: "Manuell", value: "manual" },
    { label: "Automatisk", value: "automatic" },
  ];

  const [gearcars, setGearCars] = useState("");

  const handleGearCar = (carName) => {
    const isSelected = gearcars.includes(carName);
    if (gearcars == carName) {
      setGearCars("");
      // setGearCars(
      //   gearcars.filter((selectedCar) => selectedCar !== carName)
      // );
    } else {
      // setGearCars([...gearcars, carName]);
      setGearCars(carName);
    }
  };

  useEffect(() => {
    if (tabs == 7) {
      get_car_trims();
    }
  }, [tabs]);

  const get_car_trims = async () => {
    const payload = {
      // token: getLoggedObject()?.token,
      car: selectedCar,
      model: selectedmodel.id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_car_trims");
    if (isError) {
      setDataTrim([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setDataTrim(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [trimarry, setTrimArry] = useState([]);

  const do_select_trim = (val, trim) => {
    // console.log("i am s,elected")
    const new_trim = [{ overall: val, trimeselected: trim }];
    setTrimArry(new_trim);
    setTabs(8);
  };

  // FOR 8th TAB
  useEffect(() => {
    if (tabs == 8) {
      get_car_engines();
    }
  }, [tabs]);

  const get_car_engines = async () => {
    const payload = {
      // token: getLoggedObject()?.token,
      car: selectedCar,
      model: selectedmodel.id,
      gear_option: gearcars,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_car_engines");
    if (isError) {
      setDataEngine([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setDataEngine(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [enginearry, setEngineArry] = useState("");

  // FOR 8th TAB
  useEffect(() => {
    if (tabs == 9) {
      get_car_colors();
    }
  }, [tabs]);

  const get_car_colors = async () => {
    const payload = {
      // token: getLoggedObject()?.token,
      car: selectedCar,
      model: selectedmodel.id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_car_colors");
    if (isError) {
      setDataColor([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setDataColor(data?.data);
        setDataAdditional(data?.additional);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [colorarry, setColorArry] = useState("");
  const [additionid, setAdditionID] = useState(0);
  const [addprice, setAddPrice] = useState([]);

  const addPriceFinal = async (vv) => {
    const index = addprice.indexOf(vv);
    if (index !== -1) {
      const updatedAddPrice = addprice.filter((id) => id !== vv);
      setAddPrice(updatedAddPrice);
    } else {
      const updatedAddPrice = [...addprice, vv];
      setAddPrice(updatedAddPrice);
    }
  };

  const regiosn = [
    { value: "Hela Sverige", id: 1 },
    { value: " Angränsande Län", id: 2 },
    { value: "Välj Län ", id: 3 },
  ];
  const [selectedregion, setSelectedRegion] = useState("");

  useEffect(() => {
    if (tabs == 10) {
      get_all_regions();
    }
  }, [tabs]);

  const [swedregions, setSwedRegions] = useState([]);
  const [regiosval, setRegiosValue] = useState("");
  const [allregions, setAllRegions] = useState([]);

  const choose_regions = async (val) => {
    const isSelected = allregions.includes(val);
    if (isSelected) {
      setAllRegions(allregions.filter((selectedCar) => selectedCar !== val));
    } else {
      setAllRegions([...allregions, val]);
    }
  };

  const get_all_regions = async () => {
    const payload = {
      // token: getLoggedObject()?.token,
      car: selectedCar,
      model: selectedmodel.id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_sweden_regions");
    if (isError) {
      setSwedRegions([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setSwedRegions(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const save_car_final_step = async () => {
    if (selectedregion == 2) {
      // if(allregions.length == 0){
      //   toast.error("Please select region(s)");
      //   return;
      // }
      if (regiosval == "") {
        toast.error("Please select a region");
        return;
      }
    }
    if (selectedregion == 3) {
      if (regiosval == "") {
        toast.error("Please select a region");
        return;
      }
    }
    const final_payload = {
      car: selectedCar,
      model: selectedmodel,
      new_old: selectedOption,
      payment: selectedCars,
      fuel: fuelcars,
      gear: gearcars,
      trim: trimarry,
      engine: enginearry,
      color: colorarry,
      additional: addprice,
      region: selectedregion,
      selectedregion: regiosval,
      allregions: allregions,
    };

    if (getLoggedObject()?.token && isLoggedIn) {
      const payload = {
        car_data: final_payload,
        token: getLoggedObject()?.token ? getLoggedObject()?.token : -1,
        edit: edit,
      };
      //   console.log(payload);
      //  return;

      setLoading(true);
      const { isError, data } = await doPost(payload, "auth/do_save_user_car");
      if (isError) {
        toast.error("Something went wrong with server.");
        setLoading(false);
      } else {
        if (data.action == "success") {
          setLoading(false);
          setEdit(0);
          localStorage.removeItem("CARCOMPADDCAR");
          toast.success(
            edit == 0
              ? "Your car information has been successfully saved!"
              : "Car information updated successfully!"
          );
          navigate("/dashboard");
        } else {
          setLoading(false);
          toast.error(data?.error);
        }
      }
    } else {
      // console.log("I came here...")
      localStorage.setItem("CARCOMPADDCAR", JSON.stringify(final_payload));
      navigate("/login");
    }
  };

  const [progress, setProgress] = useState(0);

  const [edit, setEdit] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("edit")) {
      const viewValue = params.get("edit");
      setEdit(viewValue);
      do_get_specific_car_data(viewValue);
    } else {
      setEdit(0);
    }
  }, []);

  const do_get_specific_car_data = async (edid) => {
    const payload = {
      car_id: edid,
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_specific_car_data_edit"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        var row = data?.data;
        setLoading(false);
        setSelectedCar(row.car);
        setSelectedModel(row.model);
        setSelectedOption(row.new_old);
        setSelectedCars(row.payment);
        setFuelCars(row.fuel);
        setGearCars(row.gear);
        setTrimArry(row.trim);
        setEngineArry(row.engine);
        setColorArry(row.color);
        setAddPrice(row.additional);
        setSelectedRegion(row.region);
        setRegiosValue(row.selectedregion);
        if (row.region == 2) {
          setAllRegions(row.allregions);
        }

        // console.log(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("proceed")) {
      const viewValue = params.get("proceed");
      const retrievedItem = localStorage.getItem("CARCOMPADDCAR");
      if (retrievedItem != null) {
        const row = JSON.parse(retrievedItem);
        setLoading(false);
        setSelectedCar(row.car);
        setSelectedModel(row.model);
        setSelectedOption(row.new_old);
        setSelectedCars(row.payment);
        setFuelCars(row.fuel);
        setGearCars(row.gear);
        setTrimArry(row.trim);
        setEngineArry(row.engine);
        setColorArry(row.color);
        setAddPrice(row.additional);
        setSelectedRegion(row.region);
        setRegiosValue(row.selectedregion);
        setTabs(10);
      }
    }
  }, []);

  const filteredGearOptions = gearoptions.filter((option) => {
    if (option.value === "manual" && selectedmodel.manual) {
      return true;
    }
    if (option.value === "automatic" && selectedmodel.auto) {
      return true;
    }
    return false;
  });

  return (
    <div className="addNewCar-main">
      <div
        className="addNewCar-wrapper space-y-6"
        style={{ marginBottom: "0px", paddingBottom: "0px", marginTop: "40px" }}
      >
        <div className="progress_bar">
          <ul class="list-unstyled multi-steps">
            <li className={`${tabs == 1 ? "is-active" : ""}`}>Bil</li>
            <li className={`${tabs == 2 ? "is-active" : ""}`}>Modell</li>
            <li className={`${tabs == 3 ? "is-active" : ""}`}>Villkor</li>
            <li className={`${tabs == 4 ? "is-active" : ""}`}>
              Betalnings metod
            </li>
            <li className={`${tabs == 5 ? "is-active" : ""}`}>Drivmedel</li>
            <li className={`${tabs == 6 ? "is-active" : ""}`}>Växellåda</li>
            <li className={`${tabs == 7 ? "is-active" : ""}`}>Utrustning</li>
            <li className={`${tabs == 8 ? "is-active" : ""}`}>Motor</li>
            <li className={`${tabs == 9 ? "is-active" : ""}`}>Färg</li>
            <li className={`${tabs == 10 ? "is-active" : ""}`}>Klart</li>
          </ul>
        </div>
      </div>

      {/* CAR MAKE */}
      {tabs == 1 && (
        <div className="addNewCar-wrapper space-y-6">
          <div className="addNewCar-heading-container">
            <h4>Välj Märke</h4>
            <div className="btn-container">
              <button className="cancel_car_button" onClick={handleBtnClick}>
                Tillbaka
              </button>
              <button
                className={`cancel_car_button gren ${
                  selectedCar ? "" : "disabled"
                }`}
                disabled={!selectedCar}
                onClick={() => setTabs(2)}
              >
                Nästa
              </button>
            </div>
          </div>

          <div className="heading_make">Populära Märken</div>

          <div className="car_make_wrap">
            {popular.map((v, index) => (
              <div
                key={index}
                className={`car_boxes_makes ${
                  selectedCar === v.id ? "selected" : ""
                }`}
                onClick={() => setSelectedCar(v.id)}
              >
                <img src={v.image} />
                <div className="">{v.title}</div>
                <div className="hybrid_electric">
                  {v.hybrid == 1 && (
                    <>
                      <div className="hybrid_pill">Hybrid</div>
                    </>
                  )}
                  {v.electric == 1 && (
                    <>
                      <div className="electric_pill">El</div>
                    </>
                  )}
                </div>

                {selectedCar == v.id && (
                  <>
                    <div className="bar_selected">
                      <i className="fa fa-check-circle"></i>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          <div className="heading_make">Alla Märken</div>

          <div className="car_make_wrap">
            {data.map((v, index) => (
              <div
                key={index}
                className={`car_boxes_makes ${
                  selectedCar === v.id ? "selected" : ""
                }`}
                onClick={() => setSelectedCar(v.id)}
              >
                <img src={v.image} />
                <div className="">{v.title}</div>
                <div className="hybrid_electric">
                  {v.hybrid == 1 && (
                    <>
                      <div className="hybrid_pill">Hybrid</div>
                    </>
                  )}
                  {v.electric == 1 && (
                    <>
                      <div className="electric_pill">El</div>
                    </>
                  )}
                </div>

                {selectedCar == v.id && (
                  <>
                    <div className="bar_selected">
                      <i className="fa fa-check-circle"></i>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {tabs == 2 && (
        <div className="addNewCar-wrapper space-y-6 space-y-6">
          <div className="addNewCar-heading-container">
            <h4>Välj Modell</h4>
            <div className="btn-container">
              <button className="cancel_car_button" onClick={() => setTabs(1)}>
                Tillbaka
              </button>
              <button
                className={`cancel_car_button gren ${
                  selectedmodel != "" ? "" : "disabled"
                }`}
                disabled={selectedmodel == ""}
                onClick={() => setTabs(3)}
              >
                Nästa
              </button>
            </div>
          </div>

          <div className="car_make_wrap car_model_wrap">
            {datamodel.map((v, index) => (
              <div
                key={index}
                className={`car_boxes_makes ${
                  selectedmodel.id === v.id ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedModel(v);
                }}
              >
                <div className="relative">
                  <img src={v.image} alt={""} />
                </div>
                <div className="relative">
                  <span style={{ fontWeight: "bold" }}>{v.title}</span>
                  <div className="hybrid_electric">
                    {v.hybrid == 1 && (
                      <>
                        <div className="hybrid_pill">Hybrid</div>
                      </>
                    )}
                    {v.electric == 1 && (
                      <>
                        <div className="electric_pill">El</div>
                      </>
                    )}
                  </div>
                </div>

                {selectedmodel.id == v.id && (
                  <>
                    <div className="bar_selected">
                      <i className="fa fa-check-circle"></i>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {tabs == 3 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container">
              <h4>Välj vilken kondition du vill ha</h4>
              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(2)}
                >
                  Tillbaka{" "}
                </button>
                <button
                  className={`cancel_car_button gren ${
                    selectedOption ? "" : "disabled"
                  }`}
                  onClick={() => setTabs(4)}
                  disabled={!selectedOption}
                >
                  Nästa
                </button>
              </div>
            </div>

            <div className="type-options-container">
              {carOptions.map((option, index) => (
                <div
                  key={index}
                  className={`type-option ${
                    selectedOption === option.value ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOption(option.value)}
                >
                  <input
                    type="radio"
                    name="carOption"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={() => setSelectedOption(option.value)}
                  />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 4 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container">
              <h4>Vilken betalningsmetod föredrar du?</h4>
              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(3)}
                >
                  Tillbaka
                </button>
                <button
                  className={`cancel_car_button gren ${
                    selectedCars.length > 0 ? "" : "disabled"
                  }`}
                  disabled={selectedCars.length === 0}
                  onClick={() => {
                    if (selectedmodel.electric == 1) {
                      setTabs(6);
                    } else {
                      setTabs(5);
                    }
                  }}
                >
                  Nästa
                </button>
              </div>
            </div>

            <div className="type-options-container">
              <p>Flera val kan göras</p>
              {paymentOptions.map((option, index) => (
                <div
                  key={index}
                  className={`type-option ${
                    selectedCars.includes(option.value) ? "selected" : ""
                  }`}
                  onClick={() => handleCarOptionClick(option.value)}
                >
                  <input
                    type="checkbox"
                    name="carOption"
                    value={option.value}
                    checked={selectedCars.includes(option.value)}
                    onChange={() => handleCarOptionClick(option.value)}
                  />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 5 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container">
              <h4>Choose a Fuel Type</h4>
              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(4)}
                >
                  Tillbaka
                </button>
                <button
                  className={`cancel_car_button gren ${
                    fuelcars != "" ? "" : "disabled"
                  }`}
                  disabled={fuelcars === ""}
                  onClick={() => setTabs(6)}
                >
                  Nästa
                </button>
              </div>
            </div>

            <div className="type-options-container">
              <p>Flera val kan göras</p>
              {FuelOptions.map((option, index) => (
                <div
                  key={index}
                  className={`type-option ${
                    fuelcars.includes(option.value) ? "selected" : ""
                  }`}
                  onClick={() => handleFuelCar(option.value)}
                >
                  <input
                    type="checkbox"
                    name="carOption"
                    value={option.value}
                    checked={fuelcars.includes(option.value)}
                    onChange={() => handleFuelCar(option.value)}
                  />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 6 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container">
              <h4>Choose a gearbox</h4>
              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(5)}
                >
                  Tillbaka
                </button>
                <button
                  className={`cancel_car_button gren ${
                    gearcars != "" ? "" : "disabled"
                  }`}
                  disabled={gearcars === ""}
                  onClick={() => setTabs(7)}
                >
                  Nästa
                </button>
              </div>
            </div>

            <div className="type-options-container">
              <p>Flera val kan göras</p>
              {filteredGearOptions.map((option, index) => (
                <div
                  key={index}
                  className={`type-option ${
                    gearcars.includes(option.value) ? "selected" : ""
                  }`}
                  onClick={() => handleGearCar(option.value)}
                >
                  <input
                    type="checkbox"
                    name="carOption"
                    value={option.value}
                    checked={gearcars.includes(option.value)}
                    onChange={() => handleGearCar(option.value)}
                  />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 7 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container py-3">
              <h4>
                Välj utrustningspaket <br></br>
                <p>
                  Varje utrustningspaket har unika förutsättningar och skiljer
                  sig åt.
                </p>
              </h4>

              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(6)}
                >
                  Tillbaka
                </button>
                {/* <button
                    className={`cancel_car_button gren ${
                      trimarry.length > 0 ? "" : "disabled"
                    }`}
                    disabled={trimarry.length === 0}
                    onClick={() =>
                      setTabs(8)
                    }
                  >
                    Next
                  </button> */}
              </div>
            </div>

            <div className="trim_box_wrap">
              {datatrim.length == 0 && !loading && (
                <>
                  <div className="no_car_text">No Trim information found!</div>
                </>
              )}
              {datatrim.map((v, index) => (
                <div className="trim_box_outer">
                  <div className="heading_trim">{v.heading}</div>
                  <div className="des_trim">{v.description}</div>

                  {v.title.map((vv, ii) => (
                    <>
                      <div className="trim_wrapper_inner">
                        <div className="name_trimer">{vv}</div>
                        <div className="buton_trim_sel">
                          <button
                            className={`choose_trim ${
                              trimarry.length > 0 &&
                              vv === trimarry[0].trimeselected
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => do_select_trim(v, vv)}
                          >
                            Välj Utrustning
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 8 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container py-3">
              <h4>
                Välj en Motor
                <br></br>
                <p>Välj en motor som bäst passar dina vanliga resor.</p>
              </h4>

              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => setTabs(7)}
                >
                  Tillbaka
                </button>
                {/* <button
                    className={`cancel_car_button gren ${
                      enginearry != "" ? "" : "disabled"
                    }`}
                    disabled={enginearry === ""}
                    onClick={() =>
                      setTabs(9)
                    }
                  >
                    Next
                  </button> */}
              </div>
            </div>

            <div className="trim_box_wrap trim_box_engine">
              {dataengine.length == 0 && !loading && (
                <>
                  <div className="no_car_text">
                    No Engine information found!
                  </div>
                </>
              )}
              {dataengine.map((v, index) => (
                <>
                  <div className="trim_wrapper_inner">
                    <div className="left_engine_data">
                      <div className="top_left_engine">
                        <div className="name_trimer">{v.title}</div>
                        <div className="">
                          {v.engine} . {v.petrol}
                        </div>
                      </div>

                      <div className="bottom_left_engine">
                        <div className="per_div sm:w-1/4 w-full">
                          <span>Prestanda</span>
                          <div className="">{v.performance}</div>
                        </div>
                        <div className="per_div sm:w-1/4 w-full">
                          <span>Förbrukning</span>
                          <div className="">{v.efficiency}</div>
                        </div>
                        <div className="per_div sm:w-1/4 w-full">
                          <span>Utsläpp</span>
                          <div className="">{v.emissions}</div>
                        </div>
                      </div>
                    </div>

                    <div className="buton_trim_sel">
                      <button
                        className={`choose_trim ${
                          v.id === enginearry ? "selected" : ""
                        }`}
                        onClick={() => {
                          setEngineArry(v.id);
                          setTabs(9);
                        }}
                      >
                        Välj Motor
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}

      {tabs == 9 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container py-3">
              <h4>
                Anpassa din bil
                <br></br>
                <p> Gör din bil unik genom val av färg</p>
              </h4>

              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => {
                    setTabs(8);
                    setAdditionID(0);
                    setColorArry("");
                  }}
                >
                  Tillbaka
                </button>
                {/* <button
                  className={`cancel_car_button gren ${
                    colorarry != "" ? "" : "disabled"
                  }`}
                  disabled={colorarry === ""}
                  onClick={() => setTabs(10)}
                >
                  Next
                </button> */}
              </div>
            </div>

            <div className="color_wrap">
              {datacolor.length == 0 && !loading && (
                <>
                  <div className="no_car_text">No Color information found!</div>
                </>
              )}
              {datacolor.map((v, index) => (
                <>
                  <div
                    onClick={() => {
                      setColorArry(v.id);
                      setTabs(10);
                    }}
                    className={`color_box ${colorarry == v.id ? "active" : ""}`}
                  >
                    <div className="lef_colo_box">
                      <div className="circle_check"></div>
                      <div className="name_box_inner">
                        <div className="name_colr">{v.title}</div>
                        <div className="price_color">
                          {v.price == 0 ? "" : v.price}
                        </div>
                      </div>
                    </div>
                    {v.id != -1 && (
                      <div
                        className="color_swatcher"
                        style={{ background: v.color_code }}
                      ></div>
                    )}
                    {v.id == -1 && (
                      <div
                        className="color_swatcher"
                        style={{ background: 'white', border:'none', boxShadow:"none" }}
                      ></div>
                    )}
                  </div>
                </>
              ))}
            </div>

            {/* <h4>Select your additional options</h4>
            {
              dataadditional.map((v, index) => (
                <div key={index}>
                  <div onClick={() => 
                    {
                     if(additionid == v.id){
                      setAdditionID(0)
                     } else {
                      setAdditionID(v.id)
                     }
                    }
                  }>
                    <div className="heading_additional">
                      <div className="">{v.title}</div>
                      <span><i className={additionid==v.id?"fa fa-chevron-down":"fa fa-chevron-up"}></i></span>
                    </div>
                  </div>
                  {(v.id === additionid) && (
                    <div>
                      {v.other_data.map((vv, ii) => (
                        <div className={`additional_bar ${addprice.includes(vv.id) ?"active":""}`} key={ii} onClick={() => addPriceFinal(vv.id)}>
                          <div className="left_bar_add">
                            <div className="bar_check"></div>
                            <div className="bar_head_left">{vv.title}</div>
                          </div>
                          <div className="right_price_bar">
                            {vv.price}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            } */}
          </div>
        </div>
      )}

      {tabs == 10 && (
        <div className="addNewCar-main">
          <div className="addNewCar-wrapper space-y-6">
            <div className="addNewCar-heading-container py-3">
              <h4>
                Sista steget
                <br></br>
                <p>välj plats för önskade offerter</p>
              </h4>

              <div className="btn-container">
                <button
                  className="cancel_car_button"
                  onClick={() => {
                    setTabs(9);
                  }}
                >
                  Tillbaka
                </button>
                <button
                  style={{ width: "auto" }}
                  Select
                  a
                  Region
                  className={`cancel_car_button gren ${
                    selectedregion != "" ? "" : "disabled"
                  }`}
                  disabled={selectedregion === "" || loading}
                  onClick={() => save_car_final_step()}
                >
                  {loading ? <Loader /> : edit == 0 ? "Spara bil" : "UPDATE CAR"}
                </button>
              </div>
            </div>

            <div className="type-options-container">
              {regiosn.map((option, index) => (
                <div
                  key={index}
                  className={`type-option ${
                    selectedregion === option.id ? "selected" : ""
                  }`}
                  onClick={() => setSelectedRegion(option.id)}
                >
                  <input
                    type="radio"
                    name="carOption"
                    value={option.id}
                    checked={selectedregion === option.id}
                    onChange={() => setSelectedRegion(option.id)}
                  />
                  <p>{option.value}</p>
                </div>
              ))}
            </div>

            {selectedregion == 2 && (
              <>
                <h4 style={{ fontSize: "16px" }}>
                  Välj Län
                  <p>
                    Förfrågan kommer också att skickas till alla angränsande
                    regioner
                  </p>
                </h4>

                <select
                  className="region_dropdown"
                  value={regiosval}
                  onChange={(e) => setRegiosValue(e.target.value)}
                >
                  <option value={""}>--Select Region--</option>
                  {swedregions.map((v, i) => (
                    <>
                      <option value={v.id}>{v.title}</option>
                    </>
                  ))}
                </select>

                {/* <div className="regionsss_wrap">
                  <>
                      <div className={`region_boxes selected`}>{'Lappland'}<br />
                        <small>Västerbotten, Norrbotten, Ångermanland & Jämtland.</small>
                      </div>
                  </>
                  {
                    swedregions.map((v, i) => (
                      <>
                        <div className={`region_boxes ${
                      allregions.includes(v.id) ? "selected" : ""
                    }`} onClick={()=> choose_regions(v.id)}>{v.title}</div>
                      </>
                    ))
                  }
                  </div> */}
              </>
            )}

            {selectedregion == 3 && (
              <>
                <h4 style={{ fontSize: "16px" }}>
                  Välj Län
                  <p> Välj en region från listan.</p>
                </h4>

                <select
                  className="region_dropdown"
                  value={regiosval}
                  onChange={(e) => setRegiosValue(e.target.value)}
                >
                  <option value={""}>--Välj Län --</option>
                  {swedregions.map((v, i) => (
                    <>
                      <option value={v.id}>{v.title}</option>
                    </>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseMake;
