import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import BuyerSignupForm from "../components/signup/buyerSignup/buyerSignup";

const BuyerSignup = () => {
  return (
    <>
      <SubHeader subTitle="Buyer Signup" />
      <BuyerSignupForm />
    </>
  );
};

export default BuyerSignup;
