import React, { useEffect, useState, useRef } from "react";
import "./myCars.scss";

import { useNavigate, Link } from "react-router-dom";

import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { toast } from "react-toastify";
import SubHeader from "../../subHeader/subHeader";
import { Radio } from "@material-tailwind/react";
import { Checkbox } from "@material-tailwind/react";
import Loader from "../../loader/Loader";

const MyCars = () => {
  const navigate = useNavigate();
  const { getLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [mycars, setMyCars] = useState([]);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState([]);
  const [cancelreq, setCanceReq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [offer, setOffer] = useState(false);
  const [rejectpopup, setRejectPopup] = useState(false);

  const [delid, setDelID] = useState(0);
  const [tab, setTab] = useState(1);
  const [options, setOptions] = useState(false);

  const [offerid, setOfferID] = useState(0);
  const [carstorage, setCarStorage] = useState(false);

  const [trims, setTrims] = useState([]);
  const [colorsdata, setColorData] = useState([]);
  const [trimoffer, setTrimOffer] = useState("");
  const [coloroffer, setColorOffer] = useState("");
  const [price, setPrice] = useState("");
  const [offerdeatils, setOfferDetails] = useState("");
  const [offerexpired, setOfferExpired] = useState("7 days");
  const [cartype, setcartype] = useState("new");

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      get_user_cars();

      //CHECK IF CAR HAS IN STORAGE
      const retrievedItem = localStorage.getItem("CARCOMPADDCAR");
      if (retrievedItem != null) {
        const item = JSON.parse(retrievedItem);
        setCarStorage(true);
      }

      setLoading(false);
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [isLoggedIn]);

  const handleBtnClick = () => {
    navigate("/add-new-car");
  };

  const get_user_cars = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      status: 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_dealer_offers_cars"
    );
    if (isError) {
      setData([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setData(data?.data);
        setPending(data?.sent);
        setCanceReq(data?.cancel);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handleReset = () => {
    setTrimOffer("");
    setColorOffer("");
    setcartype("new");
    setNumberOfMiles("");
    setPaymentMethod({
      cash: false,
      pcp: false,
      lease: false,
    });
    setInputValues({
      cash: "",
      pcp: "",
      lease: "",
    });
  };

  const do_archive = async () => {
    if (trimoffer == "") {
      toast.error("Please select a trim option");
      return;
    }
    if (coloroffer == "") {
      toast.error("Please select a color");
      return;
    }

    if (cartype == "used") {
      if (numberOfMiles == "") {
        return toast.error("Please enter number of miles");
      }
    }

    if (!paymentMethod.cash && !paymentMethod.lease && !paymentMethod.pcp) {
      return toast.error("please select at-least one payment method");
    }

    if (paymentMethod.cash) {
      if (inputValues.cash == "") {
        return toast.error("Please Enter Cash");
      }
    }
    if (paymentMethod.lease) {
      if (inputValues.lease == "") {
        return toast.error("Please Enter Lease");
      }
    }
    if (paymentMethod.pcp) {
      if (inputValues.pcp == "") {
        return toast.error("Please Enter Pcp");
      }
    }

    const payload = {
      token: getLoggedObject()?.token,
      offer_id: offerid.notif_id,
      uID: offerid.uID,
      tirm: trimoffer,
      color: coloroffer,
      price: inputValues?.cash,
      pcp_price: inputValues?.pcp,
      lease_price: inputValues?.lease,
      no_of_miles: numberOfMiles,
      payment_method_type: paymentMethod.cash,
      pcp_payment_method: paymentMethod.pcp,
      lease_payment_method: paymentMethod.lease,
      expire_time: offerexpired,
      car_type: cartype == "new" ? 1 : 2,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_send_offer_user");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setPopUp(false);
        setOfferID(0);
        toast.success("Your offer has been submitted successfully!");
        get_user_cars();
        handleReset();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_take_skip_step = async () => {
    localStorage.removeItem("CARCOMPADDCAR");
    setCarStorage(false);
  };

  const get_dealer_cars = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/get_dealer_cars");
    if (isError) {
      setMyCars([]);
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setMyCars(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };
  useEffect(() => {
    get_dealer_cars();
  }, []);

  const do_set_popup = async (id) => {
    setPopUp(true);
    await do_get_car_data(id.notif_id);
    setOfferID(id);
  };

  const [userPayments, setUserPayments] = useState([]);
  const [ViewDeatilsPop, setViewDeatilsPop] = useState(false);

  const do_get_car_data = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/get_specific_offer_data"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setTrims(data?.trims);
        setColorData(data?.colors);
        setUserPayments(data?.payments);
        if (data?.payments?.new_old === "newAndUsed") {
          setcartype("used");
          setShowMilesInput(true);
        } else {
          setcartype("new");
          setShowMilesInput(false);
        }
        setPaymentMethod({
          cash: data?.payments?.cash,
          pcp: data?.payments?.pdpfinancing,
          lease: data?.payments?.leasing,
        });
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [OfferDetailsCars, setOfferDetailsCars] = useState({});

  const GetOfferDetails = async (id) => {
    const payload = {
      id: id,
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/getCarPayments");
    if (isError) {
      setOfferDetailsCars({});
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setOfferDetailsCars(data?.payments);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const reject_Popup = async (val) => {
    setRejectPopup(true);
    setDelID(val);
  };

  const do_cancel_Request = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: delid,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/cancel_request_");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setDelID(0);
        setRejectPopup(false);
        toast.success("Request has been rejected successfully!");
        get_user_cars();
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const set_offer_details = async (val) => {
    setOfferDetails(val);
    setOffer(true);
  };

  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % newsItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const newsItems = [
    "News title 1",
    "News title 2",
    "News title 3",
    "News title 4",
    "News title 5",
    "News title 6",
  ];

  function formatNumberWithCommas(number) {
    let numericValue = number.replace(/\D/g, "");
    numericValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numericValue;
  }

  function handleInputChange(e) {
    let inputValue = e.target.value;
    let formattedValue = formatNumberWithCommas(inputValue);
    setPrice(formattedValue);
  }

  const [paymentMethod, setPaymentMethod] = useState({
    cash: false,
    pcp: false,
    lease: false,
  });

  const [inputValues, setInputValues] = useState({
    cash: "",
    pcp: "",
    lease: "",
  });

  const handleCheckboxChange = (method) => {
    setPaymentMethod((prevMethods) => ({
      ...prevMethods,
      [method]: !prevMethods[method],
    }));
    if (!paymentMethod[method]) {
      setInputValues((prevValues) => ({
        ...prevValues,
        [method]: "",
      }));
    }
  };

  const handleInputChangeMethod = (method, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [method]: value,
    }));
  };

  const [showMilesInput, setShowMilesInput] = useState(false);
  const [numberOfMiles, setNumberOfMiles] = useState("");

  const handleCarTypeChange = (value) => {
    setcartype(value);
    if (value === "used") {
      setShowMilesInput(true);
    } else {
      setShowMilesInput(false);
      setNumberOfMiles("");
    }
  };

  const handleNumberOfMilesChange = (e) => {
    setNumberOfMiles(e.target.value);
  };

  const handleCancel = () => {
    setPopUp(false);
    setRejectPopup(false);
    handleReset();
  };

  const [Newsdata, SetNewsdata] = useState([]);

  const Getnewletter = async () => {
    const payload = {
      // slug: "frequently-asked-questions",
    };
    const { isError, data } = await doPost(payload, `Auth/getAllNews`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        SetNewsdata(data?.data);
      } else {
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    Getnewletter();
  }, []);

  return (
    <div className="mycars-main">
      <SubHeader subTitle={`Welcome "${getLoggedObject()?.fullname}"`} />
      <div className="mycars-wrapper">
        <div className="dashboarddealer_space">
          <div className="cars_left_dash_dealer">
            <div className="wd100">
              <h4
                style={{
                  textTransform: "uppercase",
                  fontSize: "40px",
                  marginBottom: "20px",
                }}
              >
                My Requests
              </h4>
            </div>
            <>
              <div className="tabs_wrap">
                <div
                  className={`tabs ${tab == 1 ? "selected" : ""}`}
                  onClick={() => setTab(1)}
                >
                 Väntande förfrågningar 
                </div>
                <div
                  className={`tabs ${tab == 2 ? "selected" : ""}`}
                  onClick={() => setTab(2)}
                >
                Skickade förfrågningar 
                </div>
                <div
                  className={`tabs ${tab == 3 ? "selected" : ""}`}
                  onClick={() => setTab(3)}
                >
               Avvisade förfrågningar
                </div>
              </div>
            </>

            <div className="mycars-type cars_Dealer_type">
              {tab == 1 && data.length == 0 && !loading && (
                <>
                  <div className="no_car">
                    <div className="no_car_text cst_car wd100">
                      No Requests received!
                    </div>
                  </div>
                </>
              )}

              {tab == 2 && pending.length == 0 && !loading && (
                <>
                  <div className="no_car">
                    <div className="no_car_text cst_car wd100">
                      No Requests sent!
                    </div>
                  </div>
                </>
              )}

              {tab == 3 && cancelreq.length == 0 && !loading && (
                <>
                  <div className="no_car">
                    <div className="no_car_text cst_car wd100">
                      No Rejected Requests!
                    </div>
                  </div>
                </>
              )}

              {tab == 1 &&
                data.map((data, index) => {
                  return (
                    <div className="mycars-cards" key={index}>
                      <div className="relative">
                        <img src={data.image} alt="Car" />
                      </div>
                      <p className="mycars-name">{data.title}</p>
                      <p className="mycars-intro">
                        {data.trime_val} - {data.trim}
                      </p>
                      <p className="mycars-intro">
                        <div className="car_into_flex">
                          <div className="">
                            {data.engine_1}, {data.engine_petrol}{" "}
                            {data.additional_data.length > 0 && <>.</>}
                          </div>
                          <div className="optios_car">
                            {data.additional_data.length > 0 && (
                              <div
                                onClick={() => setOptions(data.additional_data)}
                              >
                                {data.additional_data.length} Options{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </p>
                      <div className="mycars-divider" />
                      {ViewDeatilsPop && (
                        <div className="h-screen w-full fixed top-0 left-0 bg-[#00000099] z-[999]">
                          <div className="inner_image">
                            <div className="box_inner_image">
                              <h4
                                style={{
                                  fontSize: "16px",
                                  marginBottom: "20px",
                                }}
                              >
                                Car Requirements
                              </h4>
                              <div className="box_price_pop">
                                <table className="pop_table wd100">
                                  {Object.keys(OfferDetailsCars).map((key) => (
                                    <tr key={key}>
                                      <th>{key}</th>
                                      <td>{OfferDetailsCars[key]}</td>
                                    </tr>
                                  ))}
                                </table>
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginTop: "20px",
                                }}
                                onClick={() => setViewDeatilsPop(false)}
                              >
                                Close
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <p className="mycars-color">
                        {data.color == -1 ? (
                          <div className="flex justify-between w-full">
                            <div className="w-max" style={{ color: "#ff6262" }}>
                              Any Color
                            </div>
                            <span
                              onClick={() => {
                                setViewDeatilsPop(true);
                                GetOfferDetails(data.notif_id);
                              }}
                              className="text-green-900 hover:underline cursor-pointer mr-2"
                            >
                              View details
                            </span>
                          </div>
                        ) : (
                          <div className="flex justify-between w-full">
                            <div>
                              <div
                                className="cirlce-colorr"
                                style={{ background: data.color_code }}
                              ></div>
                              {data.color}
                            </div>
                            <span
                              onClick={() => {
                                setViewDeatilsPop(true);
                                GetOfferDetails(data.notif_id);
                              }}
                              className="text-green-900 hover:underline cursor-pointer mr-2"
                            >
                              View details
                            </span>
                          </div>
                        )}
                      </p>
                      <div className="mycars-btn-container gap_spac_bt">
                        <button
                          className="rjct_offer"
                          onClick={() => reject_Popup(data.notif_id)}
                        >
                          Reject
                        </button>
                        <button
                          className="acc_off"
                          onClick={() => do_set_popup(data)}
                        >
                          Send Offer
                        </button>
                      </div>
                    </div>
                  );
                })}

              {tab == 2 &&
                pending.map((data, index) => {
                  return (
                    <div className="mycars-cards" key={index}>
                      <div className="relative">
                        <img src={data.image} alt="Car" />
                      </div>
                      <p className="mycars-name">{data.title}</p>
                      <p className="mycars-intro">
                        {data.trime_val} - {data.trim}
                      </p>
                      <p className="mycars-intro">
                        <div className="car_into_flex">
                          <div className="">
                            {data.engine_1}, {data.engine_petrol}{" "}
                            {data.additional_data.length > 0 && <>.</>}
                          </div>
                          <div className="optios_car">
                            {data.additional_data.length > 0 && (
                              <div
                                onClick={() => setOptions(data.additional_data)}
                              >
                                {data.additional_data.length} Options{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </p>
                      <div className="mycars-divider" />
                      <p className="mycars-color">
                        {data.color == -1 ? (
                          <>
                            <div className="" style={{ color: "#ff6262" }}>
                              Any Color
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="cirlce-colorr"
                              style={{ background: data.color_code }}
                            ></div>
                            {data.color}
                          </>
                        )}
                      </p>
                      <div className="mycars-btn-container">
                        <button
                          className="mycars-view-specs-btn"
                          onClick={() => set_offer_details(data)}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  );
                })}

              {tab == 3 &&
                cancelreq.map((data, index) => {
                  return (
                    <div className="mycars-cards" key={index}>
                      <div className="relative">
                        <img src={data.image} alt="Car" />
                      </div>
                      <p className="mycars-name">{data.title}</p>
                      <p className="mycars-intro">
                        {data.trime_val} - {data.trim}
                      </p>
                      <p className="mycars-intro">
                        <div className="car_into_flex">
                          <div className="">
                            {data.engine_1}, {data.engine_petrol}{" "}
                            {data.additional_data.length > 0 && <>.</>}
                          </div>
                          <div className="optios_car">
                            {data.additional_data.length > 0 && (
                              <div
                                onClick={() => setOptions(data.additional_data)}
                              >
                                {data.additional_data.length} Options{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </p>
                      <div className="mycars-divider" />
                      <p className="mycars-color">
                        {data.color == -1 ? (
                          <>
                            <div className="" style={{ color: "#ff6262" }}>
                              Any Color
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="cirlce-colorr"
                              style={{ background: data.color_code }}
                            ></div>
                            {data.color}
                          </>
                        )}
                      </p>
                      <div className="gap_spac_bt">
                        <button className="rjct_offer wd100">
                          Request Rejected
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="new_leftsss">
            <div className="wd100">
              <h4 style={{ marginBottom: "0px" }}>News</h4>
            </div>

            <div className="news_section">
              <div className="wrapper_news">
                {Newsdata.map((item, index) => (
                  <div
                    key={index}
                    className={`news_box ${
                      index === currentItemIndex ? "active" : ""
                    }`}
                  >
                    <h5>{item?.title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: item?.content }} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* // MY CARS */}
        <div className="pt-4">
          <div className="mycars-heading-container">
            <h4>Mina Bilar
            </h4>
          </div>
          <div className="car_make_wrap dashboard_wrp dealer_Cars_mobile">
            {mycars.length == 0 && !loading && (
              <>
                <div className="no_car">
                  <div className="no_car_text cst_car wd100">
                    No cars assigned yet! please connect administratot!
                  </div>
                </div>
              </>
            )}

            {mycars.map((v, index) => (
              <div key={index} className={`car_boxes_makes small_Cars`}>
                <img src={v.image} />
                <div className="">{v.title}</div>
                <div className="hybrid_electric">
                  {v.hybrid == 1 && (
                    <>
                      <div className="hybrid_pill">Hybrid</div>
                    </>
                  )}
                  {v.electric == 1 && (
                    <>
                      <div className="electric_pill"> El</div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {popup && (
          <>
            <div className="outer_image">
              <div className="inner_image">
                <div className="box_inner_image custom_mar">
                  <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                    Send offer ({offerid.title})
                  </h4>

                  <p>
                    Choose options below you want to sent offer to the user.{" "}
                  </p>
                  <div className="">
                    <h5 style={{ marginBottom: "10px" }}>Choose Trim</h5>

                    <div className="trims_offer_wrap">
                      {trims.map((v, i) => (
                        <>
                          <div
                            className={`off_trm_box ${
                              trimoffer == v ? "selected" : ""
                            }`}
                            onClick={() => setTrimOffer(v)}
                          >
                            {v}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <h5 style={{ marginBottom: "10px" }}>Choose Color</h5>

                    <div className="trims_offer_wrap">
                      {colorsdata.map((v, i) => (
                        <>
                          <div
                            className={`off_trm_box dp_flex ${
                              coloroffer == v.id ? "selected" : ""
                            }`}
                            onClick={() => setColorOffer(v.id)}
                          >
                            <div
                              className="color_round"
                              style={{ background: v.code }}
                            ></div>
                            <div className="">{v.title}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <h5 style={{ marginBottom: "10px" }}>Offer Expired Time</h5>
                    <div className="w-full border flex">
                      <Radio
                        name="offertime"
                        onChange={(e) => setOfferExpired(e.target.value)}
                        defaultChecked
                        value={"7 days"}
                        label="7 days"
                        color="green"
                      />
                      <Radio
                        name="offertime"
                        onChange={(e) => setOfferExpired(e.target.value)}
                        value={"14 days"}
                        label="14 days"
                        color="green"
                      />
                      <Radio
                        name="offertime"
                        onChange={(e) => setOfferExpired(e.target.value)}
                        value={"30 days"}
                        label="30 days"
                        color="green"
                      />
                      <Radio
                        name="offertime"
                        onChange={(e) => setOfferExpired(e.target.value)}
                        value={"unlimited"}
                        label="unlimited"
                        color="green"
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <h5 style={{ marginBottom: "10px" }}>Car type</h5>
                    <div className="w-full border flex flex-col">
                      {cartype == "new" && (
                        <div className="flex items-center">
                          <Radio
                            name="cartype"
                            checked={cartype === "new"}
                            onChange={() => handleCarTypeChange("new")}
                            value="new"
                            label="New"
                            color="green"
                          />
                        </div>
                      )}

                      {cartype == "used" && (
                        <div className="flex items-center">
                          <Radio
                            name="cartype"
                            checked={cartype === "used"}
                            onChange={() => handleCarTypeChange("used")}
                            value="used"
                            label="Ny och Begagnad"
                            color="green"
                          />
                        </div>
                      )}

                      {showMilesInput && (
                        <div className="flex items-center mt-4 form-input login-field">
                          <input
                            type="number"
                            placeholder="Number of Miles"
                            className="mb-2 form-input login-field"
                            value={numberOfMiles}
                            min={0}
                            onChange={handleNumberOfMilesChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <h5 style={{ marginBottom: "10px" }}>Betalnings metod
                    </h5>
                    <div className="w-full border flex flex-col">
                      {userPayments?.cash && (
                        <div>
                          <div className="flex items-center">
                            <Checkbox
                              color="green"
                              disabled={true}
                              checked={paymentMethod.cash}
                              onChange={() => handleCheckboxChange("cash")}
                              inputProps={{ "aria-label": "Cash" }}
                            />
                            <label> kontantbetalning</label>
                          </div>
                          {paymentMethod.cash && (
                            <div className="relative w-full">
                              <input
                                type="number"
                                placeholder="Cash"
                                min={0}
                                className="mb-2 form-input login-field"
                                value={inputValues.cash}
                                onChange={(e) =>
                                  handleInputChangeMethod(
                                    "cash",
                                    e.target.value
                                  )
                                }
                              />
                              <span className="absolute text-[#23503A] w-max right-2 top-4 font-semibold">
                                SEK
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {userPayments?.pdpfinancing && (
                        <div>
                          <div className="flex items-center">
                            <Checkbox
                              color="green"
                              disabled={true}
                              checked={paymentMethod.pcp}
                              onChange={() => handleCheckboxChange("pcp")}
                              inputProps={{ "aria-label": "PCP" }}
                            />
                            <label>PCP</label>
                          </div>
                          {paymentMethod.pcp && (
                            <div className="w-full relative">
                              <input
                                type="number"
                                placeholder="PCP"
                                min={0}
                                className="mb-2 form-input login-field"
                                value={inputValues.pcp}
                                onChange={(e) =>
                                  handleInputChangeMethod("pcp", e.target.value)
                                }
                              />
                              <span className="absolute text-[#23503A] w-max right-2 top-4 font-semibold">
                                SEK
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {userPayments?.leasing && (
                        <div>
                          <div className="flex items-center">
                            <Checkbox
                              color="green"
                              disabled={true}
                              checked={paymentMethod.lease}
                              onChange={() => handleCheckboxChange("lease")}
                              inputProps={{ "aria-label": "Lease" }}
                            />
                            <label>Lease</label>
                          </div>
                          {paymentMethod.lease && (
                            <div className="w-full relative">
                              <input
                                type="number"
                                min={0}
                                placeholder="Lease"
                                className="mb-2 form-input login-field"
                                value={inputValues.lease}
                                onChange={(e) =>
                                  handleInputChangeMethod(
                                    "lease",
                                    e.target.value
                                  )
                                }
                              />
                              <span className="absolute text-[#23503A] w-max right-2 top-4 font-semibold">
                                SEK
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="popup_flex">
                    <button
                      className="new_car_add"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        background: "#fb7878",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="new_car_add"
                      onClick={() => do_archive()}
                    >
                      {loading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {rejectpopup && (
          <>
            <div className="outer_image">
              <div className="inner_image">
                <div className="box_inner_image">
                  <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                    Cancel Request
                  </h4>
                  <p className="small_pop_text">
                    Are you sure you want to cancel this request? it won't be
                    undone!
                  </p>

                  <div className="popup_flex">
                    <button
                      className="new_car_add"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        background: "#fb7878",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={loading}
                      className="new_car_add"
                      onClick={() => do_cancel_Request()}
                    >
                      {loading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {offer && (
          <>
            <div className="outer_image">
              <div className="inner_image">
                <div className="box_inner_image">
                  <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>
                    Offer Details
                  </h4>
                  <div className="box_price_pop">
                    <table className="pop_table wd100">
                      <tr>
                        <th>Car</th>
                        <td>{offerdeatils.title}</td>
                      </tr>
                      <tr>
                        <th>Trim</th>
                        <td>{offerdeatils.trim}</td>
                      </tr>
                      <tr>
                        <th>Color</th>
                        <td>{offerdeatils.color}</td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td>SEK {offerdeatils.price}</td>
                      </tr>
                    </table>
                  </div>
                  <div
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginTop: "20px",
                    }}
                    onClick={() => setOffer(false)}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyCars;
