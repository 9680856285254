import React from "react";
import "./reviews.scss";
import ReviewsImage from "../../../assets/images/reviews.jpeg";

const Reviews = () => {
  return (
    <div className="reviews-wrapper-main ">
      <div className="reviews-wrapper space-y-4">
        <h4>Recensioner</h4>
        <img src={ReviewsImage} alt={ReviewsImage} />
      </div>
    </div>
  );
};

export default Reviews;
