import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ backendData = {} }) => {
  const categories = Object.keys(backendData).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  const series = [
    { name: "Mottagna förfrågningar", data: [] },
    { name: "Skickade förfrågningar", data: [] },
    { name: "Avvisade förfrågningar", data: [] },
    { name: "Redan fakturerat ", data: [] },
  ];

  categories.forEach((month) => {
    series[0].data.push(backendData[month].req);
    series[1].data.push(backendData[month].offer);
    series[2].data.push(backendData[month].reject);
    series[3].data.push(backendData[month].invoice);
  });

  const chartOptions = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LineChart;
