import React from "react";
import SubHeader from "../components/subHeader/subHeader";
import CustomerLoginForm from "../components/login/customerLogin/customerLogin";

const CustomerLogin = () => {
  return (
    <>
      <SubHeader subTitle="Customer Login" />
      <CustomerLoginForm />
    </>
  );
};

export default CustomerLogin;
