import React, { useEffect, useState, useRef } from "react";
import "../login.scss";
import { useNavigate, Link } from "react-router-dom";
import GLOGIN from "./../../../assets/images/g_login.svg";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import useAuth from "./../../../hooks/useAuth";
import { doPost } from "./../../../utils/apiCalls";
import { validateEmail } from "./../../../utils/functions";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../../lib/Redux/slices/userslice";
import Loader from "../../loader/Loader";

const CustomerLoginForm = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/dashboard");
  };

  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const logged = await checkLogin();
    if (logged) {
      navigate("/dashboard");
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [isLoggedIn]);

  const do_login = async () => {
    if (email == "") {
      toast.error("Please enter your email address!");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }
    if (password == "") {
      toast.error("Please enter your password!");
      return;
    }

    const payload = {
      email: email,
      password: password,
      type: 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_login");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        // setUser(data?.data);
        dispatch(setUser(data?.data));
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_google_login = async (name, emailg, pic, id) => {
    const payload = {
      email: emailg,
      password: id,
      fullname: name,
      pic: pic,
      id: id,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_signup_google");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        dispatch(setUser(data?.data));
        window.location.href = `${window.location.origin}/dashboard`;
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const google_login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenResponse?.access_token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          do_google_login(data?.name, data?.email, data?.picture, data?.sub);
        } else {
          console.error(
            "Request failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw error;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form-wrapper">
      <h4>Login to your Account</h4>
      <div className="login-form-container">
        <input
          className="form-input login-field"
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="relative">
          <input
            className="form-input login-field"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div
            className="icon_show_"
            onClick={() => togglePasswordVisibility()}
          >
            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
          </div>
        </div>

        <div className="">
          <Link to="/forgot-password" className="for_small">
            <small>Forgot Password?</small>
          </Link>
        </div>
        {/* <div className="checkbox-container">
          <input type="checkbox" />
          <p>Remember Me</p>
        </div> */}
        <button
          disabled={loading}
          className="login-btn"
          onClick={() => do_login()}
        >
          {loading ? <Loader /> : "Login"}
        </button>

        <Link
          className="form-group google_login"
          onClick={() => google_login()}
        >
          <img src={GLOGIN} />
          <span>Continue with Google</span>
        </Link>
        <div className="btn-bottom-content">
          <p className="have-account mobiles_small">
            Don't have an account? <Link to="/buyer-sign-up">Signup</Link>
          </p>
          <p className="have-account mobiles_small">
            Are you a dealer?
            <Link to="/dealer-sign-in" style={{ marginLeft: "5px" }}>
              Log in as a dealer
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerLoginForm;
