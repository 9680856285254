import { useEffect, useState } from "react";
import "./header.scss";
import { Link, NavLink } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import useAuth from "./../../hooks/useAuth";
import ColorLogo from "../../assets/images/color-logo.jpeg";

export const Header = () => {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [scroll, setScroll] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // ${scroll ? "is_sticky" : ""}
  return (
    <header className={`header-wrapper is_sticky`}>
      <div className="container fluid flex items-center">
        <Link className="logo mr-auto" to="/">
          <img src={ColorLogo} alt="logo" />
        </Link>
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsMenuOpen(false);
          }}
        >
          <button className={`hamburger fa fa-bars`} onClick={toggleMenu} />

          <div className={`navigation-wrap flex ${isMenuOpen ? "show" : ""}`}>
            <ul className="list-style-none flex items-center navigation">
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to="/">
                Hem
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to="/about-us">
                Om Oss
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to={getLoggedObject()?.token?"/add-new-car":"/add-new-car"}>
                Köp Bil
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)} to="/contact-us">
                Kontakta Oss
                </NavLink>
              </li>
              <div className="login-wrapper butn flex">
                <div className="login-buttons">
                  <Link onClick={() => setIsMenuOpen(false)} to="/login">
                  Logga in
                  </Link>
                </div>
              </div>
            </ul>
          </div>
        </OutsideClickHandler>
      </div>
    </header>
  );
};
