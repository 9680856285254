import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider2 from "../../../assets/images/sliderImage1.jpg";
import Slider3 from "../../../assets/images/sliderImage2.jpg";
import Slider1 from "../../../assets/images/sliderImage3.jpg";
import "./banner.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { doPost } from "../../../utils/apiCalls";

const BannerSlider = () => {
  const navigate = useNavigate();
  const customImageStyles = {
    maxHeight: "60vh",
    maxWidth: "100%",
    objectFit: "cover",
  };

  const overlayText = [
    "Empowering Car Shoppers",
    "Empowering Car Shoppers",
    "Empowering Car Shoppers",
  ];

  const [Data, setData] = useState([]);

  const GetStaticpagesInfo = async () => {
    const payload = {
      // slug: "terms-conditions",
    };
    const { isError, data } = await doPost(payload, `Auth/getAllBanners`);
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data?.status) {
        setData(data?.data);
      } else {
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    GetStaticpagesInfo();
  }, []);

  return (
    <div className="banner-wrapper flex column-direction content-justify-center">
      <Carousel
        showStatus={false}
        showThumbs={false}
        dynamicHeight={false}
        autoPlay={true}
      >
        {Data.map((text, index) => (
          <div key={index} className="image-container">
            <div className="overlay"></div>
            <img
              src={text?.image}
              alt={`${index + 1}`}
              style={customImageStyles}
            />
            <div className="text-overlay">
              <p className="leading-[50px]">{text?.title}</p>
              <span className="leading-10">{text?.description}</span>
              <button
                className="buy-car-btn"
                onClick={() => navigate("/add-new-car")}
                h
              >
                {/* Buy Car */}
                Välj din bil
              </button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default BannerSlider;
