import React, { useEffect, useState } from "react";
import LineChart from "../../components/charts/Linechart";
import useAuth from "../../hooks/useAuth";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";

const Reports = () => {
  const [TotalData, setTotalData] = useState([]);
  const [LastSixMonth, setLastSixMonth] = useState();
  const [Loading, setLoading] = useState(false);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();

  const getDealrerDetails = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, `auth/getGraphData`);
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data?.status) {
        setLoading(false);
        setTotalData(data?.data);
        setLastSixMonth(data?.ghraf_data);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    }
  };

  useEffect(() => {
    getDealrerDetails();
  }, []);

  return (
    <div className="w-[95%] m-auto py-5">
      <h1 className="text-2xl font-semibold py-5">My Reports</h1>

      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 py-4">
        {Loading && "Loading..."}
        {TotalData &&
          TotalData.map((report, index) => {
            return (
              <div
                key={index}
                className="p-10 bg-white border-2 hover:scale-105 duration-200 space-y-2 shadow-lg rounded-md cursor-pointer"
              >
                <p className="text-5xl font-semibold text-center">
                  {` ${report?.name === "Total Invoice Clear" ? "SEK" : ""}${
                    report?.count
                  }`}
                </p>
                <h1 className="text-sm font-medium text-center">
                  {report?.name}
                </h1>
              </div>
            );
          })}
      </div>

      <h1 className="text-2xl font-semibold py-5">Last Six Months</h1>

      <LineChart backendData={LastSixMonth} />
    </div>
  );
};

export default Reports;
