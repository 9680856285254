import React from "react";
import ArchivedCarsComponent from "../components/dashboard/archivedCars/archivedCars";

const ArchivedCars = () => {
  return (
    <div className="py-5">
      <ArchivedCarsComponent />
    </div>
  );
};

export default ArchivedCars;
