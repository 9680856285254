import React, { useState } from "react";
import Newcars from "../Newcars";
import Caroffer from "../../../components/caroffer/Caroffer";
import { useSelector } from "react-redux";
import Offers from "..";

const NewOffers = () => {
  const { CarOffers = [] } = useSelector((state) => state.web);
  return (
    <Offers>
      {CarOffers.length > 0
        ? CarOffers.map((offer, index) => {
            return (
              <div key={index} className="pt-4">
                <Caroffer
                  header={offer?.dealer_name}
                  Totalreviws={offer?.delar_reviews}
                  Price={offer?.price}
                  Save={offer?.save_price}
                  date={offer?.expire_time}
                  color={offer?.color_code}
                  persent={offer?.save_percentage}
                  colorname={offer?.color_title}
                  average={offer?.delar_rating}
                  offerId={offer?.id}
                  did={offer?.dID}
                  pcpprice={offer?.pcp_price}
                  leasingPrice={offer?.lease_price}
                  miles={offer?.no_of_miles}
                />
              </div>
            );
          })
        : "No Data Found"}
    </Offers>
  );
};

export default NewOffers;
